import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  ToastService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
} from 'two-app-ui';
import {Contact, QueryParameter, TimeLineEvent} from 'two-core';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import TleService from '../../services/TleService';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import ContactsService from '../../services/ContactsService';
import ContactDetail from './ContactDetail';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface RouteProps {
  id: string;
}

interface State {
  loadingContact: boolean;
  loadingSecondaryView: boolean;
  contact: Contact | undefined;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
}

class ContactComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  contactsService: ContactsService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingContact: false,
      loadingSecondaryView: false,
      contact: undefined,
      events: [],
      items: [],
      showEditDialog: false,
    };
    this.toast = React.createRef();

    this.hideEditDialog = this.hideEditDialog.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;

    const id = this.props.match.params.id;
    this.loadContact(id);
    this.loadEvents(id);

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.contactUpdate) {
        this.loadContact(id);
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadContact(id: string) {
    this.setState({loadingContact: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.contactsService
      ?.getContacts(params)
      .then(data => {
        const contact = (data.records as Contact[])[0];
        this.setState({contact: contact, loadingContact: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact load failed, please try again.');
        console.error(error);
        this.setState({loadingContact: false});
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'contact',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  showEditDialog() {
    this.setState({
      showEditDialog: true,
    });
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const editAction = {
      icon: ['far', 'pencil'] as IconProp,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditDialog();
      },
    };
    return [editAction];
  }

  render() {
    const {contact, items} = this.state;

    return contact ? (
      <>
        <TwoEntityComponent title={`${contact.first_name} ${contact.last_name}`} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingContact ? <ContactDetail contact={contact} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={['far', 'calendar-alt']} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={contact.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(ContactComponent);
