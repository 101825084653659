import React from 'react';
import {AppContext, MessageService, ToastService, TwoDialog} from 'two-app-ui';
import {Order, OrderPatch} from 'two-core';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import './Order.scss';
import {messages} from '../../config/messages';

interface Props {
  showDialog: boolean;
  onHide: (saved: boolean) => void;
  toast: React.RefObject<Toast>;
  order?: Order;
  orderPatch: OrderPatch;
}

interface State {
  showDialog: boolean;
  loading: boolean;
}

class SubmitOrderConfirmDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
      loading: false,
    };

    this.onCancel = this.onCancel.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onShow = this.onShow.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
  }

  onCancel() {
    this.props.onHide(false);
  }

  onShow() {
    this.setState({loading: false});
  }

  async onSubmit() {
    const {order} = this.props;
    if (order?.id) {
      this.updateOrder();
    } else {
      this.createOrder();
    }
  }

  async createOrder() {
    this.setState({loading: true});
    const newOrder = {
      ...this.props.order,
      ...this.props.orderPatch,
      stage: 'New',
    } as Order;

    return this.ordersService
      ?.createOrder(newOrder)
      .then(() => {
        this.toastService?.showSuccess(
          this.props.toast,
          `Order ${newOrder.id} ${newOrder.reference} created successfully.`
        );
        this.props.onHide(true);
        MessageService.sendMessage(messages.orderCreated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, order creation failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateOrder() {
    this.setState({loading: true});
    const order = this.props.order!;
    const orderPatch = {...this.props.orderPatch, stage: 'New'} as OrderPatch;
    return this.ordersService
      ?.updateOrder(order.id!, orderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Order ${order.id} ${order.reference} updated successfully.`);
        MessageService.sendMessage(messages.orderUpdated);
        this.props.onHide(true);
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, edit failed, please try again.');
        this.setState({loading: false});
      });
  }

  render() {
    const {order, orderPatch} = this.props;

    const dialogBody = (
      <div className="w-100 p-mx-2">
        <p className="p-text-bold ">
          You are about to submit the order on behalf of the customer. Are you sure you want to do it?
        </p>
        <p>
          Once the order is submitted, changes can not be made to the order. If unsure, keep the order as an Estimate
          until you are ready to submit the order.
        </p>
      </div>
    );

    const orderId = order?.id ?? '';
    const orderReference = orderPatch?.reference ?? order?.reference ?? '';
    const headerTitle = `Submit Order ${orderId} ${orderReference}`;

    return (
      <TwoDialog
        headerTitle={headerTitle}
        showDialog={this.props.showDialog}
        className={'p-col-10 p-col-offset-1'}
        onHide={this.onCancel}
        loading={this.state.loading}
        saveButtonTitle="Submit Order"
        onSave={this.onSubmit}
        onShow={this.onShow}
      >
        {dialogBody}
      </TwoDialog>
    );
  }
}
export default SubmitOrderConfirmDialog;
