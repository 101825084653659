import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, PriceListVersion} from 'two-core';

class PriceListVersionService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.priceListVersions ?? '';
  }

  async getPriceListVersions(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updatePriceListVersion(id: string, data: PriceListVersion): Promise<PriceListVersion> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as PriceListVersion);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async releasePriceListVersion(id: number) {
    return this.post(`${this.endpoint}/${id}/release`, {})
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createPriceListVersion(
    priceListVersion: PriceListVersion,
    copyFromPLVersionID?: number
  ): Promise<PriceListVersion> {
    return this.post(`${this.endpoint}`, {
      priceListVersion: priceListVersion,
      copyFromPLVersionID: copyFromPLVersionID,
    })
      .then(data => {
        return Promise.resolve(data as PriceListVersion);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default PriceListVersionService;
