import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, OrderItemsComponent, ToastService, TwoDialog, UsersService} from 'two-app-ui';
import {
  Order,
  OrderPatch,
  Location,
  QueryParameter,
  LocationReference,
  Address,
  FreightType,
  OrderItem,
  PaProductDefinition,
  Field,
  OrderStage,
} from 'two-core';
import {Toast} from 'primereact/toast';
import OrdersService from '../../services/OrdersService';
import './Order.scss';
import {messages} from '../../config/messages';
import {Dropdown, DropdownChangeParams, DropdownProps} from 'primereact/dropdown';
import LocationsService from '../../services/LocationsService';
import ProductsService from '../../services/ProductsService';
import {Button} from 'primereact/button';
import SubmitOrderConfirmDialog from './SubmitOrderConfirmDialog';
import {orderStages} from '../../config/values';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  order?: Order; //existing order
  newOrder?: Order; // new order
}

interface State {
  showDialog: boolean;
  showSubmitOrderDialog: boolean;
  orderPatch: OrderPatch;
  loading: boolean;
  locations: Location[];
  selectedLocation?: Location;
  selectedStage?: OrderStage;
  productDefinitions: PaProductDefinition[];
  clonedItems: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
}

class AddEditOrderDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;
  locationsService: LocationsService | null = null;
  productsService: ProductsService | null = null;
  usersService: UsersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showDialog: false,
      showSubmitOrderDialog: false,
      orderPatch: {},
      loading: false,
      locations: [],
      productDefinitions: [],
      clonedItems: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
    };

    this.onSave = this.onSave.bind(this);
    this.onSaveAndSubmit = this.onSaveAndSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onInputShippingAddressChange = this.onInputShippingAddressChange.bind(this);
    this.addressSelectedOptionTemplate = this.addressSelectedOptionTemplate.bind(this);
    this.addressOptionTemplate = this.addressOptionTemplate.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.onHideSubmitConfirmDialog = this.onHideSubmitConfirmDialog.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;
    this.locationsService = this.context.locationsService;
    this.productsService = this.context.productsService;
    this.usersService = this.context.usersService;
  }

  async loadLocations() {
    const filters = [];
    if (this.props.order?.owner) {
      filters.push(
        JSON.stringify({
          field: 'dealership_id',
          value: this.props.order.owner,
        })
      );
    } else if (this.props.newOrder?.owner) {
      filters.push(
        JSON.stringify({
          field: 'dealership_id',
          value: this.props.newOrder.owner,
        })
      );
    }

    const sortByStringyfied = JSON.stringify({
      field: 'id',
      direction: 'ASC',
    });

    const params: QueryParameter = {
      orderBys: [sortByStringyfied],
      aggregate: false,
      filters: filters,
    };

    return this.locationsService?.getLocations(params).then(data => {
      const locations = data.records as Location[];
      const order = this.props.order;
      const shippingAddress = order?.shipping_address;
      if (shippingAddress) {
        if ((order.shipping_address as Address).street) {
          const location = locations.find(value => value.address.id === order.shipping_address?.id);
          this.setState({selectedLocation: location});
        } else {
          const selectedNewLocation = locations.find(
            value => value.id === (order.shipping_address as LocationReference).id
          );
          this.setState({
            selectedLocation: selectedNewLocation,
          });
        }
      }
      this.setState({
        locations: locations,
      });
    });
  }

  async loadProductDefinitions(revisionId: number | undefined, companyId: string) {
    return this.productsService?.getProductsDefinitionsForCompany(companyId, revisionId).then(data => {
      const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
      this.setState({
        productDefinitions: productDefinitions,
      });
    });
  }

  onShow() {
    this.setState({loading: true});
    const order = this.props.order;
    const newOrder = this.props.newOrder;

    const loads = [];
    loads.push(this.loadLocations());
    loads.push(this.loadProductDefinitions(order?.revision_id, order?.owner ?? newOrder!.owner));
    this.cloneItems();

    Promise.all(loads)
      .catch(error => {
        console.error('Failed Loading Data:' + error);
        this.toastService?.showError(this.props.toast, 'Failed loading data, please refresh and try again.');
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  /**
   * We need to create deep copy of items
   */
  cloneItems() {
    const clonedItems = (structuredClone(this.props.order?.items ?? []) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    this.setState({
      clonedItems: clonedItems,
    });
  }

  onHide() {
    this.setState({
      showDialog: false,
      clonedItems: [],
      orderPatch: {},
      showSubmitOrderDialog: false,
    });
    this.props.onHide();
  }

  async onSave() {
    const {order} = this.props;
    const {orderPatch} = this.state;
    if (Object.keys(orderPatch).length) {
      if (this.validate()) {
        if (order?.id) {
          this.updateOrder();
        } else {
          this.createOrder();
        }
      }
    } else {
      //nothing changed -> close the dialog
      this.onHide();
    }
  }

  validate(): boolean {
    const order = this.props.order ?? this.props.newOrder;
    const {orderPatch} = this.state;
    const {invalidItemMessagesMap} = this.state;

    const errors: string[] = [];
    const itemsErrors = [];
    const reference = orderPatch.reference ?? order!.reference;
    if (!reference?.length) {
      errors.push('Reference field is empty.');
    }
    const stage = orderPatch.stage ?? order!.stage;
    if (!stage) {
      errors.push('Stage is empty.');
    }
    const shippingAddress = orderPatch.shipping_address ?? order!.shipping_address;
    if (!shippingAddress) {
      errors.push('Shipping address is empty.');
    }
    const items = orderPatch.items ?? order!.items;
    if (!items?.length) {
      errors.push('Order must have at least one item.');
    }
    if (invalidItemMessagesMap?.size) {
      for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
        itemsErrors.push(
          <div>
            Item {itemIndex} is invalid:
            {messages.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </div>
        );
      }
    }

    if (errors.length || itemsErrors.length) {
      const errorText = (
        <>
          {!!errors.length && (
            <div>
              Form is invalid:
              {errors.map((error, index) => {
                return <li key={index}>{error}</li>;
              })}
            </div>
          )}
          {!!itemsErrors.length && itemsErrors}
        </>
      );
      this.toastService?.showError(this.props.toast, errorText);
      MessageService.sendMessage(messages.orderCanNotBeSaved);
      return false;
    }
    return true;
  }

  async onSaveAndSubmit() {
    this.setState({showSubmitOrderDialog: true});
  }

  async createOrder() {
    this.setState({loading: true});
    const newOrder = {
      ...this.props.newOrder,
      revision_id: this.state.productDefinitions[0].revision_id,
      ...this.state.orderPatch,
    } as Order;

    return this.ordersService
      ?.createOrder(newOrder)
      .then(() => {
        this.toastService?.showSuccess(
          this.props.toast,
          `Order ${newOrder.id} ${newOrder.reference} created successfully.`
        );
        this.onHide();
        MessageService.sendMessage(messages.orderCreated);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, order creation failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateOrder() {
    this.setState({loading: true});
    const order = this.props.order!;
    const orderPatch = this.state.orderPatch;

    return this.ordersService
      ?.updateOrder(order.id!, orderPatch)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, `Order ${order.id} ${order.reference} updated successfully.`);
        MessageService.sendMessage(messages.orderUpdated);
        this.onHide();
      })
      .catch(() => {
        this.toastService?.showError(this.props.toast, 'Sorry, edit failed, please try again.');
      });
  }

  onInputReferenceChange(value: string) {
    const orderPatch = this.state.orderPatch;
    const updatedOrder = {
      ...orderPatch,
      reference: value,
    };
    this.setState({orderPatch: updatedOrder});
  }

  onInputDeliveryNoteChange(value: string) {
    const orderPatch = this.state.orderPatch;
    const updatedOrderPatch = {
      ...orderPatch,
      freight_options: {
        freight_type: 'made2freight' as FreightType,
        delivery_note: value,
      },
    };
    this.setState({orderPatch: updatedOrderPatch});
  }

  onInputShippingAddressChange = (e: DropdownChangeParams) => {
    const orderPatch = this.state.orderPatch;
    const newAddress = e.value.address;

    const updatedOrderPatch = {
      ...orderPatch,
      shipping_address: {
        address: newAddress.street + ', ' + newAddress.suburb + ', ' + newAddress.state + ', ' + newAddress.country,
        id: e.value.id,
      },
    };
    this.setState({orderPatch: updatedOrderPatch, selectedLocation: e.value});
  };

  onStageChange = (e: DropdownChangeParams) => {
    const orderPatch = this.state.orderPatch;

    const updatedOrderPatch = {
      ...orderPatch,
      stage: e.value,
    };
    this.setState({orderPatch: updatedOrderPatch});
  };

  onItemsChange(newItems: OrderItem[]) {
    const orderPatch = this.state.orderPatch;

    const updatedOrderPatch = {
      ...orderPatch,
      items: newItems,
    };
    this.setState({
      orderPatch: updatedOrderPatch,
      clonedItems: newItems,
    });
  }

  addressSelectedOptionTemplate(location: Location, props: DropdownProps) {
    if (location) {
      return (
        <div>
          {location.address.street +
            ', ' +
            location.address.suburb +
            ', ' +
            location.address.state +
            ', ' +
            location.address.country}
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  }

  addressOptionTemplate(location: Location) {
    return (
      <div>
        {location.address?.street +
          ', ' +
          location.address?.suburb +
          ', ' +
          location.address?.state +
          ', ' +
          location.address?.country}
      </div>
    );
  }

  onHideSubmitConfirmDialog(saved: boolean) {
    if (saved) {
      this.onHide();
    } else {
      this.setState({showSubmitOrderDialog: false});
    }
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  render() {
    const {order, newOrder} = this.props;
    const {orderPatch, locations} = this.state;
    const stage = orderPatch?.stage ?? order?.stage ?? newOrder?.stage;

    const dialogBody = (
      <div className="p-fluid w-100 p-mx-2">
        <div className="p-field p-grid">
          <label htmlFor="reference" className="p-col-1">
            reference
          </label>
          <div className="p-col-11">
            <InputText
              value={orderPatch?.reference ?? order?.reference ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.onInputReferenceChange(value);
              }}
            />
          </div>
        </div>
        {this.usersService?.hasPermission('order-manage') && (
          <div className="p-field p-grid">
            <label htmlFor="stage" className="p-col-1">
              stage
            </label>
            <div className="p-col-11">
              <Dropdown placeholder="Select stage" value={stage} options={orderStages} onChange={this.onStageChange} />
            </div>
          </div>
        )}
        <div className="p-field p-grid">
          <label htmlFor="shipping_address" className="p-col-1">
            shipping address
          </label>
          <div className="p-col-11">
            <Dropdown
              placeholder="Select shipping address"
              value={this.state.selectedLocation}
              options={locations}
              onChange={this.onInputShippingAddressChange}
              dataKey="id"
              optionLabel="id"
              valueTemplate={this.addressSelectedOptionTemplate}
              itemTemplate={this.addressOptionTemplate}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label htmlFor="deliverynote" className="p-col-1">
            delivery note
          </label>
          <div className="p-col-11">
            <InputText
              value={orderPatch?.freight_options?.delivery_note ?? order?.freight_options?.delivery_note ?? ''}
              onChange={e => {
                const value = e.target.value;
                this.onInputDeliveryNoteChange(value);
              }}
            />
          </div>
        </div>
        <div className="p-field p-grid h-20">
          {!!this.state.productDefinitions.length && (
            <OrderItemsComponent
              mode={'advanced_edit'}
              items={this.state.clonedItems}
              productDefinitions={this.state.productDefinitions}
              onItemsChanged={this.onItemsChange}
              setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
            />
          )}
        </div>
      </div>
    );

    let saveButtonLabel;
    if (stage === 'Estimate') {
      saveButtonLabel = 'Save as Estimate';
    } else {
      saveButtonLabel = 'Save';
    }
    const saveAndSubmitButtonLabel = process.env.ORDER_ADD_EDIT_DIALOG_SAVE_SUBMIT_BUTTON_LABEL ?? 'Save and Submit';
    const footer = !this.state.loading ? (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className="p-button-text" onClick={this.onHide} autoFocus />
        <Button className="p-ml-2" label={saveButtonLabel} onClick={this.onSave} />
        {this.usersService?.hasAnyPermission(['orders-submit-onbehalf', 'orders-manage']) && stage === 'Estimate' && (
          <Button className="p-ml-2" label={saveAndSubmitButtonLabel} onClick={this.onSaveAndSubmit} />
        )}
      </div>
    ) : (
      <></>
    );

    return (
      <>
        <TwoDialog
          headerTitle={order ? 'Edit Order - ' + order.id : 'Add Order'}
          showDialog={this.props.showDialog}
          onShow={this.onShow}
          onHide={this.onHide}
          loading={this.state.loading}
          footer={footer}
          width={70}
        >
          {dialogBody}
        </TwoDialog>
        <SubmitOrderConfirmDialog
          showDialog={this.state.showSubmitOrderDialog}
          onHide={this.onHideSubmitConfirmDialog}
          toast={this.props.toast}
          order={this.props.order ?? this.props.newOrder}
          orderPatch={this.state.orderPatch}
        />
      </>
    );
  }
}
export default AddEditOrderDialog;
