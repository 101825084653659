import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {
  ApiListResponse,
  ApiResponse,
  QueryParameter,
  PriceListProductCosting,
  PriceListProductCostingPatch,
} from 'two-core';

class PriceListProductCostingsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.productCostings ?? '';
  }

  async getProductCostings(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateProductCosting(data: PriceListProductCostingPatch): Promise<PriceListProductCosting> {
    return this.patch(
      `${this.endpoint}/price-list-versions/${data.price_list_version_id}/products/${data.product_id}`,
      data
    )
      .then(data => {
        return Promise.resolve(data as PriceListProductCosting);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createProductCosting(data: PriceListProductCosting): Promise<PriceListProductCosting> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as PriceListProductCosting);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async copyProductCosting(copy_from_id: number, copy_to_id: number): Promise<PriceListProductCosting> {
    return this.post(`${this.endpoint}/copy-from-version`, {
      copy_from_id,
      copy_to_id,
    })
      .then(data => {
        return Promise.resolve(data as PriceListProductCosting);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default PriceListProductCostingsService;
