import {Chip} from 'primereact/chip';
import React from 'react';
import {Company, TwoSeparators} from 'two-core';
import {InputSwitch} from 'primereact/inputswitch';
import {Divider} from 'primereact/divider';

interface Props {
  company: Company;
}

class CompanyDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const company = this.props.company;
    const fittingProviders = company.fitting_providers?.map(provider => {
      return (
        <>
          <p>{provider?.trading_as}</p>
        </>
      );
    });

    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="name" className="p-col-12 p-md-3">
            entity name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="trading_as" className="p-col-12 p-md-3">
            trading as
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.trading_as ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="account_number" className="p-col-12 p-md-3">
            acc number
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.account_number ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="business_entity" className="p-col-12 p-md-3">
            entity type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.business_entity ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="tax_id" className="p-col-12 p-md-3">
            tax id
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.tax_id ?? ''}</span>
          </div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="phone_number" className="p-col-12 p-md-3">
            phone
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.phone_number ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center">
          <label htmlFor="accounts_emails" className="p-col-12 p-md-3">
            accounts emails
          </label>
          <div className="p-col-12 p-md-9">
            {company.accounts_emails &&
              company.accounts_emails.split(TwoSeparators.EMAIL_LINE).map((email, i) => {
                return <Chip key={i} className="p-fluid p-mr-1 p-mb-1" label={email} />;
              })}
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="delivery_emails" className="p-col-12 p-md-3">
            delivery emails
          </label>
          <div className="p-col-12 p-md-9">
            {company.delivery_emails &&
              company.delivery_emails.split(TwoSeparators.EMAIL_LINE).map((email, i) => {
                return <Chip key={i} className="p-fluid p-mr-1 p-mb-1" label={email} />;
              })}
          </div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="billing_address" className="p-col-12 p-md-3">
            billing address
          </label>
          <div className="p-col-12 p-md-9">
            <span>
              {company.billing_address &&
                `${company.billing_address.country ?? ''}, ${company.billing_address.street ?? ''}, ${
                  company.billing_address.suburb ?? ''
                }, ${company.billing_address.state ?? ''}`}
            </span>
          </div>
        </div>

        <Divider />

        <div className="p-d-flex p-ai-center">
          <label htmlFor="state" className="p-col-12 p-md-3">
            state
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.state ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="sales_rep" className="p-col-12 p-md-3">
            sales rep
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.sales_rep_user?.full_name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.stage ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="is_distributor" className="p-col-12 p-md-3">
            is distributor
          </label>
          <div className="p-col-12 p-md-9">
            <InputSwitch name="is_distributor" checked={company.is_distributor ?? false} disabled={true} />
          </div>
        </div>
        {!company.is_distributor ? (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="parent" className="p-col-12 p-md-3">
              parent
            </label>
            <div className="p-col-12 p-md-9">
              <span>{company.parent_company?.distributor_key ?? ''}</span>
            </div>
          </div>
        ) : (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="distributor_key" className="p-col-12 p-md-3">
              distributor key
            </label>
            <div className="p-col-12 p-md-9">
              <span>{company.distributor_key ?? ''}</span>
            </div>
          </div>
        )}
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="fitting_types" className="p-col-12 p-md-3">
            fitting types
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.fitting_types ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="does_quotes" className="p-col-12 p-md-3">
            does quotes
          </label>
          <div className="p-col-12 p-md-9">
            <InputSwitch name="does_quotes" checked={company.does_quotes} disabled={true} />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="fits_for_others" className="p-col-12 p-md-3">
            fits for others
          </label>
          <div className="p-col-12 p-md-9">
            <InputSwitch name="fits_for_others" checked={company.fits_for_others} disabled={true} />
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="fitting_providers" className="p-col-12 p-md-3">
            fitting providers
          </label>
          <div className="p-col-12 p-md-9">
            <span>{fittingProviders ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="fits_for_others" className="p-col-12 p-md-3">
            does orders
          </label>
          <div className="p-col-12 p-md-9">
            <InputSwitch name="does_orders" checked={company.does_orders} disabled={true} />
          </div>
        </div>
        <Divider />

        <div className="p-d-flex p-ai-center">
          <label htmlFor="external_id" className="p-col-12 p-md-3">
            external id
          </label>
          <div className="p-col-12 p-md-9">
            <span>{company.external_id ?? ''}</span>
          </div>
        </div>

        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="folder" className="p-col-12 p-md-3">
            folder
          </label>
          <div className="p-col-12 p-md-9">
            <span></span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center p-mt-2">
          <label htmlFor="alarms" className="p-col-12 p-md-3">
            alarms
          </label>
          <div className="p-col-12 p-md-9">
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyDetail;
