import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faSirenOn,
  faTag,
  faUserFriends,
  faBuilding,
  faPlus,
  faStickyNote,
  faBan,
  faUserSlash,
  faList,
  faCalendarAlt,
  faPencil,
  faWarehouse,
  faEnvelope,
  faFile,
  faFileImage,
  faTruck,
  faFileCircleExclamation,
  faFileCheck,
  faFilePlus,
  faIndustryWindows,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';

import {
  TwoAppFrame,
  AppMenuItem,
  AppContext,
  AppMenuItemTemplate,
  AppMenuItemSeparator,
  AuthService,
  ToastService,
  UsersService,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import './scss/App.scss';
import CompanyListComponent from './components/Companies/CompanyListComponent';
import TleService from './services/TleService';
import OrdersService from './services/OrdersService';
import ContactsService from './services/ContactsService';
import LocationsService from './services/LocationsService';
import CompanyContactService from './services/CompanyContactService';
import CompaniesService from './services/CompaniesService';
import StatesService from './services/StatesService';
import InvoicesService from './services/InvoicesService';
import PriceListListComponent from './components/PriceList/PriceListListComponent';
import PriceListsService from './services/PriceListsService';
import PriceListVersionService from './services/PriceListVersionService';
import PriceListDefinitionRevisionsService from './services/PriceListDefinitionRevisionsService';
import PriceListComponent from './components/PriceList/PriceListComponent';
import PriceListVersionComponent from './components/PriceListVersion/PriceListVersionComponent';
import PriceListProductCostingsService from './services/PriceListsProductCostingsService';
import CompanyComponent from './components/Company/CompanyComponent';
import ContactListComponent from './components/Contacts/ContactListComponent';
import ContactComponent from './components/Contact/ContactComponent';
import OrderComponent from './components/Order/OrderComponent';
import AllOrdersComponent from './components/AllOrders/AllOrdersComponent';
import EstimateOrdersComponent from './components/Estimates/EstimateOrdersComponent';
import DrawingOrdersComponent from './components/Drawings/DrawingOrdersComponent';
import ShippingOrdersComponent from './components/Shipping/ShippingOrdersComponent';
import DeliveredOrdersComponent from './components/Delivered/DeliveredOrdersComponent';
import ProductionOrdersComponent from './components/Production/ProductionOrdersComponent';
import NewOrdersComponent from './components/New/NewOrdersComponent';
import ProductsService from './services/ProductsService';

library.add(
  faSirenOn,
  faTag,
  faUserFriends,
  faBuilding,
  faPlus,
  faEnvelope,
  faStickyNote,
  faBan,
  faUserSlash,
  faList,
  faCalendarAlt,
  faPencil,
  faWarehouse,
  faCheck
);

const authService = new AuthService();
const companiesService = new CompaniesService(authService);
const tleService = new TleService(authService);
const ordersService = new OrdersService(authService);
const contactsService = new ContactsService(authService);
const locationsService = new LocationsService(authService);
const employeesService = new CompanyContactService(authService);
const statesService = new StatesService(authService);
const toastService = new ToastService();
const invoicesService = new InvoicesService(authService);
const priceListsService = new PriceListsService(authService);
const priceListVersionsService = new PriceListVersionService(authService);
const priceListDefinitionRevisionsService = new PriceListDefinitionRevisionsService(authService);
const priceListProductCostingsService = new PriceListProductCostingsService(authService);
const usersService = new UsersService(authService);
const productsService = new ProductsService(authService);

class App extends React.Component {
  static contextType = AppContext;

  menu: AppMenuItem[];

  constructor(props: {}) {
    super(props);

    this.state = {};

    this.menu = [
      {
        label: 'Alarms',
        faIcon: faSirenOn,
        badgeId: 'alarmBadge',
        to: '/alarms',
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
      },
      {
        separator: true,
        template: () => {
          return <AppMenuItemSeparator />;
        },
      },
      {
        label: 'Customers',
        expanded: true,
        items: [
          {
            label: 'Companies',
            faIcon: faBuilding,
            badgeId: 'alarmBadge',
            to: '/companies',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Contacts',
            faIcon: faUserFriends,
            badgeId: 'alarmBadge',
            to: '/contacts',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          /* {
            label: 'Invoices',
            faIcon: faFileInvoiceDollar,
            badgeId: 'alarmBadge',
            to: '/invoices',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },*/
        ],
      },
      /*     {
        label: 'Invoices',
        expanded: true,
        items: [
          {
            label: 'Invoices',
            faIcon: faFileInvoiceDollar,
            badgeId: 'alarmBadge',
            to: '/invoices',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Price List',
            faIcon: faMoneyCheckAlt,
            badgeId: 'alarmBadge',
            to: '/price-lists',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
        ],
      },*/
      {
        label: 'Orders',
        expanded: true,
        className: 'my-orders',
        items: [
          {
            label: 'All',
            faIcon: faList,
            to: '/orders',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Estimates',
            faIcon: faFile,
            to: '/estimates',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'New',
            faIcon: faFilePlus,
            to: '/new',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Drawings',
            faIcon: faFileImage,
            to: '/drawings',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'In Production',
            faIcon: faIndustryWindows,
            to: '/production',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'In Shipping',
            faIcon: faTruck,
            to: '/shipping',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Delivered',
            faIcon: faFileCheck,
            to: '/delivered',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
          {
            label: 'Repairs',
            faIcon: faFileCircleExclamation,
            to: '/repairs',
            template: (item: AppMenuItem, options: MenuItemOptions) => {
              return <AppMenuItemTemplate item={item} options={options} />;
            },
          },
        ],
      },
    ];
  }

  render() {
    const values = {
      companiesService: companiesService,
      tleService: tleService,
      ordersService: ordersService,
      contactsService: contactsService,
      locationsService: locationsService,
      employeesService: employeesService,
      toastService: toastService,
      statesService: statesService,
      authService: authService,
      invoicesService: invoicesService,
      priceListsService: priceListsService,
      priceListVersionsService: priceListVersionsService,
      priceListDefinitionRevisionsService: priceListDefinitionRevisionsService,
      priceListProductCostingsService: priceListProductCostingsService,
      usersService: usersService,
      productsService: productsService,
    };

    return (
      <TwoAppFrame menuItems={this.menu} contextValues={values}>
        <div className="topframe"></div>
        <>
          <Switch>
            <Route path="/alarms"></Route>
            <Route path="/company/:id">
              <CompanyComponent />
            </Route>
            <Route path="/companies">
              <CompanyListComponent />
            </Route>
            <Route path="/invoices"></Route>
            <Route path="/price-lists">
              <PriceListListComponent />
            </Route>
            <Route path="/price-list/:id">
              <PriceListComponent />
            </Route>
            <Route path="/price-list-version/:id">
              <PriceListVersionComponent />
            </Route>
            <Route path="/contacts">
              <ContactListComponent />
            </Route>
            <Route path="/contact/:id">
              <ContactComponent />
            </Route>
            <Route path="/order/:id">
              <OrderComponent />
            </Route>
            <Route path="/orders">
              <AllOrdersComponent />
            </Route>
            <Route path="/estimates">
              <EstimateOrdersComponent />
            </Route>
            <Route path="/new">
              <NewOrdersComponent />
            </Route>
            <Route path="/drawings">
              <DrawingOrdersComponent />
            </Route>
            <Route path="/production">
              <ProductionOrdersComponent />
            </Route>
            <Route path="/shipping">
              <ShippingOrdersComponent />
            </Route>
            <Route path="/delivered">
              <DeliveredOrdersComponent />
            </Route>
          </Switch>
        </>
      </TwoAppFrame>
    );
  }
}

export default App;
