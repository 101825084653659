import {PriceListProductFreightSurcharge, PriceListProductPriceItem} from 'two-core';
import React from 'react';

interface Props {
  items: PriceListProductPriceItem[] | PriceListProductFreightSurcharge[];
}

class PriceListProductPriceItemComponent extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.items.map((item: PriceListProductPriceItem | PriceListProductFreightSurcharge) => {
          return (
            <div key={item.sku}>
              {'title' in item && item.title && (
                <div className="p-d-flex p-ai-center">
                  <label htmlFor="stage" className="p-col-12 p-md-3">
                    title ({item.sku})
                  </label>
                  <div className="p-col-12 p-md-9">
                    <span>{item.title}</span>
                  </div>
                </div>
              )}
              <div className="p-d-flex p-ai-center">
                <label htmlFor="stage" className="p-col-12 p-md-3">
                  items ({item.sku})
                </label>
                <div className="p-col-12 p-md-9">
                  {item.value_type === 'picklist' ? (
                    <table
                      style={{
                        borderSpacing: '0',
                      }}
                    >
                      <thead
                        style={{
                          background: '#E9EBED',
                          margin: '0px',
                        }}
                      >
                        <tr
                          style={{
                            background: '#E9EBED',
                            margin: '0',
                          }}
                        >
                          {Array.isArray(item.value) &&
                            item.value.map(valueItem => {
                              return (
                                <th
                                  key={new Date().getTime().toString() + Math.random()}
                                  style={{
                                    background: '#E9EBED',
                                    border: '1px solid black',
                                    margin: '0px',
                                    padding: '5px',
                                  }}
                                >
                                  {valueItem.key}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          margin: '0px',
                        }}
                      >
                        <tr
                          style={{
                            margin: '0',
                          }}
                        >
                          {Array.isArray(item.value) &&
                            item.value.map(valueItem => {
                              return (
                                <td
                                  key={new Date().getTime().toString() + Math.random()}
                                  style={{
                                    border: '1px solid black',
                                    textAlign: 'center',
                                  }}
                                >
                                  {valueItem.value}
                                </td>
                              );
                            })}
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default PriceListProductPriceItemComponent;
