import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Contact, QueryParameter} from 'two-core';

class ContactsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.contacts ?? '';
  }

  async getContacts(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateContact(id: string, data: Contact): Promise<Contact> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Contact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createContact(data: Contact): Promise<Contact> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Contact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default ContactsService;
