export const messages = {
  companyUpdated: 'company-updated',
  locationUpdate: 'location-updated',
  contactUpdate: 'contact-updated',
  subDealerUpdate: 'subDealer-updated',
  initTableMenuItems: 'init-table-menu-items',
  priceListUpdated: 'price-list-updated',
  priceListCreated: 'price-list-created',
  priceListVersionCreated: 'price-list-version-created',
  orderUpdated: 'order-updated',
  orderCreated: 'order-created',
  topSelectionChanged: 'top-selection-changed',
  orderCanNotBeSaved: 'order-can-not-be-saved',
};
