import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {CompanyContact} from 'two-core';

class CompanyContactService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.companyContact ?? '';
  }

  async updateCompanyContact(contact: CompanyContact): Promise<CompanyContact> {
    return this.patch(`${this.endpoint}`, contact)
      .then(data => {
        return Promise.resolve(data as CompanyContact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createCompanyContact(companyContact: CompanyContact): Promise<CompanyContact> {
    return this.post(`${this.endpoint}`, companyContact)
      .then(data => {
        return Promise.resolve(data as CompanyContact);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default CompanyContactService;
