import React from 'react';

import OrderListComponent from '../Orders/OrderListComponent';

class DrawingOrdersComponent extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent mode={'Drawings'} />;
  }
}

export default DrawingOrdersComponent;
