import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AppContext, MessageService, ToastService, UsersService} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import {Address, Company, CompanyPatch, QueryParameter, State as TwoState, User} from 'two-core';
import CompanyEditComponent from '../Company/CompanyEditComponent';
import StatesService from '../../services/StatesService';
import {DropdownChangeParams} from 'primereact/dropdown';
import {MultiSelectChangeParams} from 'primereact/multiselect';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
}

interface State {
  loading: boolean;
  companyPatch: CompanyPatch;
  parentCompanies: Company[];
  users: User[];
  states: TwoState[];
  selectedFitForIds: string[];
}

class AddCompanyDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  usersService: UsersService | null = null;
  toastService: ToastService | null = null;
  statesService: StatesService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      companyPatch: {
        name: '',
        state: 'ACT',
        deleted: false,
        last_stage_change: new Date(),
        updated_at: new Date(),
      },
      parentCompanies: [],
      users: [],
      states: [],
      selectedFitForIds: [],
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.save = this.save.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleFitForChange = this.handleFitForChange.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
    this.statesService = this.context.statesService;
  }

  async loadData() {
    this.loadUsers();
    this.loadCompanies();
    this.loadStates();
  }

  async loadUsers() {
    this.setState({loading: true});

    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];

        this.setState({
          users: users,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, user records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async loadCompanies() {
    this.setState({loading: true});

    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const companies = data.records as Company[];

        this.setState({
          parentCompanies: companies,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, parent company records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async loadStates() {
    const params: QueryParameter = {
      aggregate: true,
    };
    this.statesService
      ?.getStates(params)
      .then(data => {
        const states = data.records as TwoState[];

        this.setState({
          states: states,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, state records load failed, please try again.');
        console.error(error);
      });
  }

  async save() {
    const company = this.state.companyPatch;

    this.createCompany(company);
  }

  async createCompany(company: CompanyPatch) {
    this.setState({loading: true});
    return this.companiesService
      ?.createCompany(company)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Company created successfully.');
        this.hideDialog();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Company create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  hideDialog() {
    this.setState({
      companyPatch: {
        name: '',
        state: 'ACT',
        deleted: false,
        last_stage_change: new Date(),
        updated_at: new Date(),
      },
      loading: false,
    });
    this.props.onHide();
    MessageService.sendMessage(messages.companyUpdated);
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const company = this.state.companyPatch;
    const updatedCompany = {
      ...company,
      [e.target.name]: e.target.value,
    };
    this.setState({companyPatch: updatedCompany});
  }

  handleFitForChange(e: MultiSelectChangeParams) {
    const updatedCompany = {
      ...this.state.companyPatch,
      fit_for_ids: e.target.value,
    };
    this.setState({
      companyPatch: updatedCompany,
      selectedFitForIds: e.target.value,
    });
  }

  setBillingAddress(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const company = this.state.companyPatch;
    const address: Address = company.billing_address ?? {
      state: '',
      street: '',
      state_short: '',
      suburb: '',
      postCode: '',
      country: '',
      phoneNumber: '',
      lat: 0,
      long: 0,
    };

    const updatedAddress: Address = {
      ...address,
      [e.target.name]: e.target.value,
    };

    const updatedCompany = {
      ...company,
      billing_address: updatedAddress,
    };
    this.setState({companyPatch: updatedCompany});
  }

  renderFooter() {
    return !this.state.loading ? (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.props.onHide()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    return (
      <Dialog
        header={'Add Company'}
        visible={this.props.showDialog}
        style={{width: '90%'}}
        modal
        className="p-fluid"
        onHide={this.hideDialog}
        onShow={this.loadData}
        footer={this.renderFooter}
      >
        {!this.state.loading ? (
          <CompanyEditComponent
            companyPatch={this.state.companyPatch}
            handleInputChange={this.handleInputChange}
            users={this.state.users}
            companies={this.state.parentCompanies}
            states={this.state.states}
            handleBillingAddressChange={e => this.setBillingAddress(e)}
            handleFitForChange={this.handleFitForChange}
            selectedFitForIds={this.state.selectedFitForIds}
          />
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default AddCompanyDialog;
