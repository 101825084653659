import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import OrdersService from '../../services/OrdersService';
import {NavLink} from 'react-router-dom';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {TwoDataTable, AppColumnMenuBodyTemplate, ToastService} from 'two-app-ui';
import {AppContext} from 'two-app-ui';
import DateColumnFilter, {DateColumnFilterChangeEvent} from '../DateColumnFilter/DateColumnFilter';
import {MultiSelect} from 'primereact/multiselect';

import {formats} from '../../config/formats';
import {QueryParameter, DropdownOption, Order, ApiListResponse, Company} from 'two-core';
import '../../scss/CustomTable.scss';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputSwitchChangeParams} from 'primereact/inputswitch';

export enum AvailableStages {
  Estimate = 'Estimate',
  RepairEstimate = 'Repair Estimate',
  Draft = 'Draft',
  New = 'New',
  DrawingRequired = 'Drawing(s) Required',
  DrawingAwaitingApproval = 'Drawing(s) Awaiting Approval',
  ReDraw = 'Re-Draw',
  DrawingApproved = 'Drawing(s) Approved',
  CreditCheck = 'Credit Check',
  ScheduledForProduction = 'Scheduled For Production',
  InProduction = 'In Production',
  ProductionComplete = 'Production Complete',
  InShipping = 'In Shipping',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
}

interface Props {
  company: Company;
}

interface State {
  loading: boolean;
  items: Order[];
  totalItems: number;
  selectedItems: Order[];
  activeFilters: {};
  filters: {
    order_code: string;
    reference: string;
    size: number | undefined;
    summary: string;
    stage: string;
    created_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    submitted_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    approved_at: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    ecd: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    container: string;
    eta: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
    delivered: {
      fromDate: DateTime | null;
      toDate: DateTime | null;
    };
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  error: boolean;
}

class OrderListComponent extends React.Component<Props, State> {
  static contextType = AppContext;

  ordersService: OrdersService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      selectedItems: [],
      activeFilters: {},
      filters: {
        order_code: '',
        size: undefined,
        reference: '',
        stage: '',
        summary: '',
        created_at: {
          fromDate: null,
          toDate: null,
        },
        submitted_at: {
          fromDate: null,
          toDate: null,
        },
        approved_at: {
          fromDate: null,
          toDate: null,
        },
        ecd: {
          fromDate: null,
          toDate: null,
        },
        container: '',
        eta: {
          fromDate: null,
          toDate: null,
        },
        delivered: {
          fromDate: null,
          toDate: null,
        },
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      error: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.setChangeSelectedItems = this.setChangeSelectedItems.bind(this);

    this.codeBodyTemplate = this.codeBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.approvedAtBodyTemplate = this.approvedAtBodyTemplate.bind(this);
    this.ecdBodyTemplate = this.ecdBodyTemplate.bind(this);
    this.deliveredBodyTemplate = this.deliveredBodyTemplate.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  async componentDidMount() {
    this.ordersService = this.context.ordersService;
    this.toastService = this.context.toastService;

    this.loadData();
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  handleError(error: string): void {
    console.log(error);
    if (!this.state.error) {
      this.toastService?.showError(this.toast, error);

      this.setState({error: true});
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'owner',
        value: this.props.company.id,
      })
    );

    if (this.state.filters.order_code) {
      filters.push(
        JSON.stringify({
          field: 'id',
          value: this.state.filters.order_code,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.reference) {
      filters.push(
        JSON.stringify({
          field: 'reference',
          value: this.state.filters.reference,
          condition: 'iLike',
        })
      );
    }

    //Stages
    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.ecd.fromDate) {
      const fromDate = this.state.filters.ecd.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'freight_order.ecd',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.ecd.toDate) {
      const toDate = this.state.filters.ecd.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'factory_order.ecd',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.approved_at.fromDate) {
      const fromDate = this.state.filters.approved_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.approved_at.toDate) {
      const toDate = this.state.filters.approved_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'approved_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.created_at.fromDate) {
      const fromDate = this.state.filters.created_at.fromDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: fromDate,
          condition: '>=',
        })
      );
    }
    if (this.state.filters.created_at.toDate) {
      const toDate = this.state.filters.created_at.toDate.toISODate();
      filters.push(
        JSON.stringify({
          field: 'created_at',
          value: toDate,
          condition: '<=',
        })
      );
    }

    if (this.state.filters.size) {
      filters.push(
        JSON.stringify({
          field: 'size',
          value: this.state.filters.size,
        })
      );
    }

    if (this.state.filters.summary) {
      filters.push(
        JSON.stringify({
          field: 'summary',
          value: this.state.filters.summary,
          condition: 'iLike',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = {
      field: this.state.sortBy?.field,
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    };
    switch (sortBy.field) {
      case 'order_code':
        sortBy.field = 'id';
        break;
      case 'ecd':
        sortBy.field = 'factory_order.ecd';
        break;
      case 'container_id':
        sortBy.field = 'freight_order.container_id';
        break;
      case 'eta':
        sortBy.field = 'container.eta_to_port';
        break;
      case 'delivered':
        sortBy.field = 'freight_order.delivered_at';
        break;
    }

    const sortByStringyField = JSON.stringify(sortBy.field ? sortBy : {field: 'factory_order.ecd', direction: 'ASC'});

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: [sortByStringyField],
      aggregate: true,
    };

    this.ordersService
      ?.getOrders(params)
      .then((data: ApiListResponse) => {
        const dataRecords = (data.records as Order[]) ?? [];
        this.handleSelectedItems(dataRecords);
        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.handleError('Records load failed');
        this.setState({loading: false});
        console.log(error);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | DropdownChangeParams
      | InputSwitchChangeParams
      | DateColumnFilterChangeEvent
  ) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: Order[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Order[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Order[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Order) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  codeBodyTemplate(rowData: Order) {
    return (
      <React.Fragment>
        <AppColumnMenuBodyTemplate
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={false}
          selectedItems={this.state.selectedItems}
          handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
        >
          <NavLink to={'/order/' + rowData.id}>{rowData.id}</NavLink>
        </AppColumnMenuBodyTemplate>
      </React.Fragment>
    );
  }

  stageBodyTemplate(rowData: Order) {
    return (
      <span className={`stage-badge stage-${rowData.stage.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`}>
        {rowData.stage}
      </span>
    );
  }

  approvedAtBodyTemplate(rowData: Order) {
    const formated_approved_on = rowData.approved_at
      ? DateTime.fromISO(rowData.approved_at.toString()).toFormat(formats.date)
      : '';
    return (
      <span>
        {formated_approved_on} {rowData.approved_by}
      </span>
    );
  }
  submittedAtBodyTemplate(rowData: Order) {
    const formated_submitted = rowData.submitted_at
      ? DateTime.fromISO(rowData.submitted_at.toString()).toFormat(formats.date)
      : '';
    return (
      <span>
        {formated_submitted} {rowData.submitted_by}
      </span>
    );
  }

  createdAtBodyTemplate(rowData: Order) {
    const formated_created = rowData.created_at
      ? DateTime.fromISO(rowData.created_at.toString()).toFormat(formats.date)
      : '';
    return (
      <span>
        {formated_created} {rowData.created_by?.full_name ?? ''}
      </span>
    );
  }

  ecdBodyTemplate(rowData: Order) {
    const formattedEcd = rowData.factory_order?.ecd
      ? DateTime.fromISO(rowData.factory_order?.ecd.toString()).toFormat(formats.date)
      : '';
    return <span>{formattedEcd}</span>;
  }

  deliveredBodyTemplate(rowData: Order) {
    const formated_delivered_at = rowData.freight_order?.delivered_at
      ? DateTime.fromISO(rowData.freight_order?.delivered_at.toString()).toFormat(formats.date)
      : '';
    return <span>{formated_delivered_at}</span>;
  }

  handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams | InputSwitchChangeParams
  ) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, 1000);
  };

  availableStageOptions() {
    const allStages = AvailableStages
      ? Object.values(AvailableStages).map(o => {
          const option: DropdownOption = {label: o, value: o};
          return option;
        })
      : [];

    return allStages;
  }

  render() {
    const codeFilter = (
      <InputText
        name="order_code"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const referenceFilter = (
      <InputText
        name="reference"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const selectedItemTemplate = (value: string) => {
      if (value) {
        return (
          <span
            className={`p-mr-1 stage-badge stage-badge-filter stage-${value
              .toLowerCase()
              .replaceAll(' ', '-')
              .replaceAll(/[()]/g, '')}`}
          >
            {value}
          </span>
        );
      }

      return <></>;
    };

    const itemTemplate = (option: DropdownOption) => {
      return (
        <span
          className={`stage-badge stage-${(option.value as string)
            .toLowerCase()
            .replaceAll(' ', '-')
            .replaceAll(/[()]/g, '')}`}
        >
          {option.value}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        selectedItemTemplate={selectedItemTemplate}
        itemTemplate={itemTemplate}
        value={this.state.filters.stage}
        options={this.availableStageOptions()}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const approvedAtFilter = (
      <DateColumnFilter
        name="approved_at"
        value={this.state.filters.approved_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const createdAtFilter = (
      <DateColumnFilter
        name="created_at"
        value={this.state.filters.created_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const submittedAtFilter = (
      <DateColumnFilter
        name="submitted_at"
        value={this.state.filters.submitted_at}
        onChange={e => this.onFilterChange(e)}
      />
    );

    const etaFilter = (
      <DateColumnFilter name="eta" value={this.state.filters.eta} onChange={e => this.onFilterChange(e)} />
    );

    const deliveredFilter = (
      <DateColumnFilter name="delivered" value={this.state.filters.delivered} onChange={e => this.onFilterChange(e)} />
    );

    const ecdFilter = (
      <DateColumnFilter name="ecd" value={this.state.filters.ecd} onChange={e => this.onFilterChange(e)} />
    );

    const sizeFilter = (
      <InputText
        name="size"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const summaryFilter = (
      <InputText
        name="summary"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const containerFilter = (
      <InputText
        name="container_id"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="order_list_page_container" className={'page-container'}>
        <TwoDataTable
          pageSizeIdentifier={'order_list_page_container'}
          //sizeIdentifiers={this.props.showFilterBox ? ['filter-box'] : []}
          //          selectionMode="multiple"
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as Order[])}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
        >
          <Column
            header="Order Code"
            field="order_code"
            body={this.codeBodyTemplate}
            filter
            filterElement={codeFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Reference"
            field="reference"
            filter
            filterElement={referenceFilter}
            sortable
            style={{width: '360px'}}
            showFilterMenu={false}
          />
          <Column
            header="Size"
            field="size"
            filter
            filterElement={sizeFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Summary"
            field="summary"
            filter
            filterElement={summaryFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="Created"
            field="created_at"
            body={this.createdAtBodyTemplate}
            filter
            filterElement={createdAtFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Submitted"
            field="submitted_at"
            body={this.submittedAtBodyTemplate}
            filter
            filterElement={submittedAtFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Approved"
            field="approved_at"
            body={this.approvedAtBodyTemplate}
            filter
            filterElement={approvedAtFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="ECD"
            field="ecd"
            body={this.ecdBodyTemplate}
            filter
            filterElement={ecdFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Container"
            field="container_id"
            filter
            filterElement={containerFilter}
            sortable
            style={{width: '180px'}}
            showFilterMenu={false}
          />
          <Column
            header="ETA"
            field="eta"
            body={this.ecdBodyTemplate}
            filter
            filterElement={etaFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
          <Column
            header="Delivered"
            field="delivered"
            body={this.deliveredBodyTemplate}
            filter
            filterElement={deliveredFilter}
            sortable
            style={{width: '80px'}}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default OrderListComponent;
