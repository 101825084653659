import React from 'react';
import FilterboxComponent from '../FilterBox/FilterboxComponent';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemSeparator,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
  UsersService,
} from 'two-app-ui';
import {Column} from 'primereact/column';
import {MapOf, PriceList, PriceListVersion, QueryParameter, User, PriceListPatch} from 'two-core';
import PriceListsService from '../../services/PriceListsService';
import {Toast} from 'primereact/toast';
import {
  DataTableExpandedRows,
  DataTablePageParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import {MultiSelectChangeParams} from 'primereact/multiselect';
import PriceListVersions from '../PriceListVersion/PriceListVersions';
import {MenuItemOptions} from 'primereact/menuitem';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import AddPriceListDialog from './AddPriceListDialog';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import AddPriceListVersionDialog from '../PriceListVersion/AddPriceListVersionDialog';
import PriceListVersionService from '../../services/PriceListVersionService';

interface State {
  loading: boolean;
  items: PriceList[];
  selectedItems: PriceList[];
  pagination: {
    pageSize: number;
    offset: number;
  };
  totalItems: number;
  activeFilters: {};
  filters: {
    showItemsSwitch: boolean;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
  usersMap: MapOf<User>;
  showAddDialog: boolean;
  showAddVersionDialog: boolean;
  lastPriceList: PriceList | undefined;
}

class PriceListListComponent extends React.Component<RouteComponentProps<{}>, State> {
  static contextType = AppContext;
  priceListsService: PriceListsService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;
  usersService: UsersService | null = null;
  messageSendSubscription: Subscription = new Subscription();
  priceListVersionsService: PriceListVersionService | null = null;

  constructor(props: RouteComponentProps<{}>) {
    super(props);

    this.state = {
      loading: false,
      items: [],
      selectedItems: [],
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      totalItems: 0,
      activeFilters: {},
      filters: {
        showItemsSwitch: false,
      },
      sortBy: null,
      expandedRows: [],
      usersMap: {},
      showAddDialog: false,
      showAddVersionDialog: false,
      lastPriceList: undefined,
    };

    this.toast = React.createRef();

    this.onFilterChange = this.onFilterChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.nameTemplate = this.nameTemplate.bind(this);
    this.releaseDraft = this.releaseDraft.bind(this);
    this.setDeletedPriceList = this.setDeletedPriceList.bind(this);
  }

  componentDidMount() {
    this.priceListsService = this.context.priceListsService;
    this.priceListVersionsService = this.context.priceListVersionsService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.priceListUpdated) {
        this.loadData();
        this.setState({
          expandedRows: [],
        });
      } else if (message === messages.priceListVersionCreated) {
        this.loadLastPriceLists();
      }
    });

    this.loadData();
    this.loadUsers();
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    if (!this.state.filters.showItemsSwitch) {
      filters.push(
        JSON.stringify({
          field: 'deleted',
          value: false,
        })
      );
    }

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const dataRecords = (data.records as PriceList[]) ?? [];

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadLastPriceLists() {
    this.setState({loading: true});

    const sortBy: string[] | undefined = [];

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: [],
      orderBys: sortBy,
    };

    this.priceListVersionsService
      ?.getPriceListVersions(params)
      .then(data => {
        const version = (data.records as PriceListVersion[])[0];
        this.setState({
          loading: false,
        });
        this.props.history.push('/price-list-version/' + version.id);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadLastRecord() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    return this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const priceList = (data.records as PriceList[])[0];
        if (priceList.price_list_versions) {
          const version = priceList.price_list_versions[0];
          this.props.history.push('/price-list-version/' + version.id);
        } else {
          this.setState({
            lastPriceList: priceList,
            loading: false,
          });
        }
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, price list load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];
    const selectedItems = this.state.selectedItems;
    const selectedItemsLenght = selectedItems.length;

    const addNewMenuItem: AppMenuItem = {
      label: 'Add New Price List',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddDialog();
      },
    };
    const separator: AppMenuItem = {
      separator: true,
      template: () => {
        return <AppMenuItemSeparator />;
      },
    };
    menuItems.push(addNewMenuItem, separator);

    const addNewVersionItem: AppMenuItem = {
      label: 'Add New Version',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddVersionDialog();
      },
    };

    if (selectedItemsLenght === 1 && !selectedItems[0].deleted && !this.hasDraftVersion(selectedItems)) {
      menuItems.push(addNewVersionItem);
    }

    const releaseDraftAction: AppMenuItem = {
      label: 'Release Draft',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.releaseDraft();
      },
    };

    if (selectedItemsLenght > 0 && this.hasDraftVersion(selectedItems) && !this.hasDeletedItem(selectedItems)) {
      menuItems.push(releaseDraftAction);
    }

    const deleteAction = {
      label: 'Delete',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setDeletedPriceList(true);
      },
    };

    if (selectedItemsLenght > 0 && !this.hasDeletedItem(selectedItems)) {
      menuItems.push(deleteAction);
    }

    const unDeleteAction = {
      label: 'Un - Delete',
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.setDeletedPriceList(false);
      },
    };

    if (selectedItemsLenght > 0 && this.hasDeletedItem(selectedItems)) {
      menuItems.push(unDeleteAction);
    }

    return menuItems;
  }

  hasDraftVersion(items: PriceList[]) {
    let result = false;
    items.forEach(item => {
      result = false;
      item.price_list_versions?.forEach(version => {
        if (version !== undefined && version !== null && version.stage === 'Draft') {
          result = true;
        }
      });
    });

    return result;
  }

  hasDeletedItem(items: PriceList[]) {
    let result = false;
    items.forEach(item => {
      if (item.deleted === true) {
        result = true;
      }
    });

    return result;
  }

  showAddDialog() {
    this.setState({showAddDialog: true});
  }

  hideAddDialog() {
    this.setState({showAddDialog: false});
  }

  async saveAddDialog() {
    await this.loadLastRecord();
    this.setState({
      showAddDialog: false,
      selectedItems: [],
    });
  }

  showAddVersionDialog() {
    this.setState({showAddVersionDialog: true});
  }

  hideAddVersionDialog() {
    this.setState({showAddVersionDialog: false});
  }

  async loadUsers() {
    const userMap: MapOf<User> = {};

    const params: QueryParameter = {
      aggregate: false,
    };
    const data = await this.usersService?.getUsers(params);
    for (const user of data?.records as User[]) {
      userMap[user.id!] = user;
    }

    this.setState({
      usersMap: userMap,
    });
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  setChangeSelectedItems(items: PriceList[]) {
    this.setState({selectedItems: items});
  }

  rowExpansionTemplate(priceList: PriceList) {
    return <PriceListVersions priceList={priceList} usersMap={this.state.usersMap} />;
  }

  async setChangeSelectedItem(priceList: PriceList) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === priceList.id);
    if (!existingItem) {
      items.push(priceList);
      await this.setState({selectedItems: items});
    }
  }

  nameTemplate(priceList: PriceList) {
    return (
      <AppColumnMenuBodyTemplate
        key={priceList.id}
        rowItemIdentifier={priceList?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(priceList)}
      >
        <NavLink to={'price-list/' + priceList.id}>{`${priceList.name}` ?? ''}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  currentVersionTemplate(priceList: PriceList) {
    let result = 'n/a';

    if (
      priceList.price_list_versions &&
      priceList.price_list_versions?.length > 0 &&
      priceList.price_list_versions[0] !== null
    ) {
      const priceListCurrentVersions: PriceListVersion[] | undefined = priceList.price_list_versions?.filter(
        version => version.stage === 'Current'
      );

      if (priceListCurrentVersions && priceListCurrentVersions.length > 0) {
        result = '' + priceListCurrentVersions[priceListCurrentVersions.length - 1].index;
      }
    }

    return result;
  }

  releaseDraft() {
    this.setState({loading: true});
    const priceLists: PriceList[] = this.state.selectedItems;
    const promises: (Promise<void> | undefined)[] = [];
    if (priceLists) {
      priceLists.forEach(priceList => {
        if (priceList.price_list_versions) {
          priceList.price_list_versions.forEach(version => {
            if (version.stage === 'Draft' && version.id) {
              promises.push(this.priceListVersionsService?.releasePriceListVersion(version.id));
            }
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }

    if (promises.length) {
      Promise.all(promises)
        .then(() => {
          this.setState({loading: false, selectedItems: []});
          this.toastService?.showSuccess(this.toast, 'The Draft version is released successfully.');
          MessageService.sendMessage(messages.priceListUpdated);
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, release draft version failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  setDeletedPriceList(state: boolean) {
    this.setState({loading: true});
    const priceLists: PriceList[] = this.state.selectedItems;
    const promises: (Promise<PriceList> | undefined)[] = [];
    if (priceLists) {
      priceLists.forEach(priceList => {
        if (priceList.id) {
          const updatedPriceList: PriceListPatch = {
            deleted: state,
          };
          promises.push(this.priceListsService?.updatePriceList(priceList.id, updatedPriceList));
        } else {
          this.setState({
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }

    if (promises.length) {
      Promise.all(promises)
        .then(() => {
          this.setState({loading: false, selectedItems: []});
          this.toastService?.showSuccess(
            this.toast,
            state ? 'Price lists deleted successfully.' : 'Price lists undeleted successfully.'
          );
          MessageService.sendMessage(messages.priceListUpdated);
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, price list delete failed, please try again.');
          console.error(error);
          this.setState({loading: false});
        });
    }
  }

  assignedToTemplate(priceList: PriceList) {
    return priceList.customers?.length && priceList.customers[0] !== null ? priceList.customers.length : 0;
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  render() {
    const selectedPL = this.state.selectedItems.length > 0 ? this.state.selectedItems[0] : this.state.lastPriceList;
    return (
      <div id="price_list_page_container" className="page-container">
        <div id="filter-box" className="p-d-flex">
          <FilterboxComponent
            showItemsSwitch={this.state.filters.showItemsSwitch}
            onFilterChange={e => this.onFilterChange(e)}
            customSwitchButtonLabel={'Show Deleted'}
          />
        </div>
        <TwoDataTable
          id={'price-lists-table'}
          sizeIdentifiers={['filter-box']}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={this.state.activeFilters}
          selectionMode="multiple"
          rowExpansionTemplate={data => this.rowExpansionTemplate(data)}
          expandedRows={this.state.expandedRows}
          initMenuItems={() => this.initMenuItems()}
          addNewItemEvent={() => this.showAddDialog()}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as PriceList[])}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          pageSizeIdentifier={'price_list_page_container'}
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Name"
            field="name"
            body={priceList => this.nameTemplate(priceList)}
            style={{width: '200px'}}
          />
          <Column header="Stage" field="stage" />
          <Column header="Current version" field="current-version" body={this.currentVersionTemplate} />
          <Column header="Assigned to" field="assigned_to" body={this.assignedToTemplate} />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddPriceListDialog
          showDialog={this.state.showAddDialog}
          onHide={() => this.hideAddDialog()}
          toast={this.toast}
          priceLists={this.state.items}
          onSave={() => this.saveAddDialog()}
        />
        {selectedPL && (
          <AddPriceListVersionDialog
            showDialog={this.state.showAddVersionDialog}
            onHide={() => this.hideAddVersionDialog()}
            toast={this.toast}
            selectedPriceList={selectedPL}
          />
        )}
      </div>
    );
  }
}

export default withRouter(PriceListListComponent);
