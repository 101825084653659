import {MapOf, PriceList, PriceListProductCosting, PriceListVersion, QueryParameter, User} from 'two-core';
import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {
  AppContext,
  ToastService,
  TwoAction,
  TwoEntityComponent,
  UsersService,
  TwoEntityPanel,
  MessageService,
} from 'two-app-ui';
import TleService from '../../services/TleService';
import PriceListsService from '../../services/PriceListsService';
import PriceListVersionService from '../../services/PriceListVersionService';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import PriceListVersionDetail from './PriceListVersionDetail';
import {Accordion, AccordionTab, AccordionTabProps} from 'primereact/accordion';
import {faPencil, faPlus} from '@fortawesome/pro-light-svg-icons';
import PriceListProductCostingsService from '../../services/PriceListsProductCostingsService';
import EditPriceListVersionDialog from './EditPriceListVersionDialog';
import {messages} from '../../config/messages';
import AddPriceListVersionDialog from './AddPriceListVersionDialog';
import {Subscription} from 'rxjs';
import PriceListProductPriceItemComponent from './PriceListProductPriceItemComponent';
import PriceListProductPriceComponent from './PriceListProductPriceComponent';
import CopyPriceListVersionDialog from './CopyPriceListVersionDialog';

interface RouteProps {
  id: string;
}
interface State {
  loadingVersion: boolean;
  priceListVersion: PriceListVersion | undefined;
  showAddVersionDialog: boolean;
  loadingSecondaryView: boolean;
  usersMap: MapOf<User>;
  priceList: PriceList | undefined;
  productCostings: PriceListProductCosting[];
  showEditVersionDialog: boolean;
  showCopyDialog: boolean;
}

class PriceListComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;
  priceListVersionsService: PriceListVersionService | null = null;
  priceListsService: PriceListsService | null = null;
  priceListProductCostingsService: PriceListProductCostingsService | null = null;
  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingVersion: false,
      priceListVersion: undefined,
      showAddVersionDialog: false,
      loadingSecondaryView: false,
      usersMap: {},
      priceList: undefined,
      productCostings: [],
      showEditVersionDialog: false,
      showCopyDialog: false,
    };
    this.toast = React.createRef();
    this.loadUsers = this.loadUsers.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadProductCosting = this.loadProductCosting.bind(this);
    this.loadLastPriceLists = this.loadLastPriceLists.bind(this);
  }

  componentDidMount() {
    this.priceListVersionsService = this.context.priceListVersionsService;
    this.priceListsService = this.context.priceListsService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;
    this.priceListProductCostingsService = this.context.priceListProductCostingsService;

    this.loadData();

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.priceListVersionCreated) {
        this.loadLastPriceLists();
      } else if (message === messages.priceListUpdated) {
        this.loadData();
      }
    });
  }

  async loadData() {
    await this.loadUsers();
    this.loadPriceListVersion();
    this.loadProductCosting();
  }

  async loadUsers() {
    const userMap: MapOf<User> = {};

    const params: QueryParameter = {
      aggregate: false,
    };
    const data = await this.usersService?.getUsers(params);
    for (const user of data?.records as User[]) {
      userMap[user.id!] = user;
    }

    this.setState({
      usersMap: userMap,
    });
  }

  loadPriceListVersion() {
    this.setState({loadingVersion: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'id',
        value: this.props.match.params.id,
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.priceListVersionsService
      ?.getPriceListVersions(params)
      .then(data => {
        const version = (data.records as PriceListVersion[])[0];
        this.setState({priceListVersion: version, loadingVersion: false});
        this.loadPriceList(version.price_list_id as number);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, price list version loading failed, please try again.');
        console.error(error);
        this.setState({loadingVersion: false});
      });
  }

  async loadPriceList(id: number) {
    const filters: string[] = [
      JSON.stringify({
        field: 'id',
        value: id,
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const priceList = (data.records as PriceList[])[0];
        this.setState({priceList: priceList});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, price list loading failed, please try again.');
        console.error(error);
      });
  }

  getActions(): TwoAction[] {
    const version = this.state.priceListVersion;
    const menuActions: TwoAction[] = [];
    const editAction = {
      label: 'Edit',
      icon: faPencil,
      main: true,
      action: () => {
        this.setState({showEditVersionDialog: true});
      },
    };

    const copyAction = {
      label: 'Copy from another',
      main: false,
      action: () => {
        this.setState({showCopyDialog: true});
      },
    };

    menuActions.push(editAction);

    if (version?.stage === 'Draft') {
      const releaseAction = {
        label: 'Release',
        main: false,
        action: () => {
          this.releaseDraft();
        },
      };

      menuActions.push(releaseAction);
    } else if (!this.hasDraftVersion()) {
      const createNewVersionAction = {
        label: 'Create new Version',
        icon: faPlus,
        main: false,
        action: () => {
          this.showAddVersionDialog();
        },
      };
      menuActions.push(createNewVersionAction);
    }

    menuActions.push(copyAction);
    return menuActions;
  }

  showAddVersionDialog() {
    this.setState({
      showAddVersionDialog: true,
    });
  }

  hideAddVersionDialog() {
    this.setState({
      showAddVersionDialog: false,
    });
  }

  hideCopyVersionDialog() {
    this.setState({
      showCopyDialog: false,
    });
  }

  loadProductCosting() {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'price_list_version_id',
        value: this.props.match.params.id,
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.priceListProductCostingsService
      ?.getProductCostings(params)
      .then(data => {
        const dataRecords = (data.records as PriceListProductCosting[]) ?? [];
        this.setState({
          productCostings: dataRecords,
          loadingSecondaryView: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          'Sorry, price list version product costings loading failed, please try again.'
        );
        console.error(error);
        this.setState({loadingSecondaryView: false});
      });
  }

  hasDraftVersion() {
    const versions = this.state.priceList?.price_list_versions;
    if (versions && versions.length > 0 && versions[0] !== null) {
      const draftVersion = versions?.filter(version => version.stage === 'Draft');
      return draftVersion !== undefined && draftVersion.length > 0;
    }

    return false;
  }

  releaseDraft() {
    this.setState({loadingVersion: true});
    const {priceListVersion} = this.state;
    if (priceListVersion && priceListVersion.id && priceListVersion.stage === 'Draft') {
      this.priceListVersionsService
        ?.releasePriceListVersion(priceListVersion.id)
        .then(() => {
          this.setState({loadingVersion: false});
          this.toastService?.showSuccess(this.toast, 'The Draft version is released successfully.');
          this.loadPriceListVersion();
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, release draft version failed, please try again.');
          console.error(error);
          this.setState({loadingVersion: false});
        });
    } else {
      this.setState({loadingVersion: false});
    }
  }

  loadLastPriceLists() {
    this.setState({loadingVersion: true});

    const sortBy: string[] | undefined = [];

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: [],
      orderBys: sortBy,
    };

    this.priceListVersionsService
      ?.getPriceListVersions(params)
      .then(data => {
        const version = (data.records as PriceListVersion[])[0];
        this.setState({
          loadingVersion: false,
          priceListVersion: version,
        });
        this.props.history.push('/price-list-version/' + version.id);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loadingVersion: false});
      });
  }

  render() {
    const panelTemplate = (options: AccordionTabProps, label: string) => {
      return (
        <>
          <div className={'w-100'}>
            <div className="p-grid p-component p-m-0 p-justify-between w-100 p-align-center">
              <div>{label}</div>
            </div>
          </div>
        </>
      );
    };

    const {
      priceListVersion,
      priceList,
      usersMap,
      productCostings,
      showEditVersionDialog,
      showAddVersionDialog,
      loadingVersion,
      showCopyDialog,
    } = this.state;

    return (
      <>
        <TwoEntityComponent title={priceList?.name + ' ' + priceListVersion?.index} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!loadingVersion && priceListVersion ? (
              <PriceListVersionDetail priceListVersion={priceListVersion} userMap={usersMap} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="content" icon={['far', 'list']} tooltip="Content">
            {!loadingVersion ? (
              <div>
                <Accordion multiple activeIndex={[0]}>
                  {productCostings &&
                    productCostings.map((productCosting: PriceListProductCosting, index) => {
                      const product = productCosting.items.products;
                      if (product) {
                        return (
                          <AccordionTab headerTemplate={op => panelTemplate(op, product.name ?? '')} key={index}>
                            <PriceListProductPriceComponent item={product.product_price} />
                            {product.surcharges?.length && (
                              <div>
                                <div className="p-d-flex p-ai-center">
                                  <label htmlFor="stage" className="p-col-12 p-md-3">
                                    Surcharges
                                  </label>
                                  <div className="p-col-12 p-md-9">
                                    <hr />
                                  </div>
                                </div>
                                <PriceListProductPriceItemComponent items={product.surcharges} />
                              </div>
                            )}
                            {product.discounts?.length && (
                              <div>
                                <div className="p-d-flex p-ai-center">
                                  <label htmlFor="stage" className="p-col-12 p-md-3">
                                    Discounts
                                  </label>
                                  <div className="p-col-12 p-md-9">
                                    <hr />
                                  </div>
                                </div>
                                <PriceListProductPriceItemComponent items={product.discounts} />
                              </div>
                            )}
                            <div>
                              <div className="p-d-flex p-ai-center">
                                <label htmlFor="stage" className="p-col-12 p-md-3">
                                  Freight
                                </label>
                                <div className="p-col-12 p-md-9">
                                  <hr />
                                </div>
                              </div>
                              <PriceListProductPriceItemComponent items={[product.freight_surcharge]} />
                            </div>
                          </AccordionTab>
                        );
                      } else {
                        return (
                          <AccordionTab header={'Not Represented Product'} key={index}>
                            no data
                          </AccordionTab>
                        );
                      }
                    })}
                </Accordion>
              </div>
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="invoices" icon={['far', 'list']} tooltip="Invoices">
            {!loadingVersion ? <div>invoices</div> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        {priceListVersion && (
          <EditPriceListVersionDialog
            showDialog={showEditVersionDialog}
            onHide={() => {
              this.setState({showEditVersionDialog: false});
            }}
            toast={this.toast}
            productCostings={productCostings}
            priceListName={priceList?.name ?? ''}
            priceListVersion={priceListVersion}
          />
        )}
        {priceList && (
          <AddPriceListVersionDialog
            showDialog={showAddVersionDialog}
            toast={this.toast}
            selectedPriceList={priceList}
            onHide={() => this.hideAddVersionDialog()}
          />
        )}
        {priceList && priceListVersion && (
          <CopyPriceListVersionDialog
            currentVersion={priceListVersion}
            showDialog={showCopyDialog}
            toast={this.toast}
            selectedPriceList={priceList}
            onHide={() => this.hideCopyVersionDialog()}
          />
        )}
      </>
    );
  }
}

export default withRouter(PriceListComponent);
