import {PriceList} from 'two-core';
import React from 'react';
import {DropdownChangeParams} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';

interface Props {
  priceList: PriceList;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
}

const PriceListEditComponent = ({priceList, handleInputChange}: Props) => {
  return (
    <>
      <div id="price-list-detail-item">
        <div className="p-grid">
          <div className="p-col-12 p-md-12 p-lg-12">
            <div className="p-grid p-ai-center">
              <label htmlFor="name" className="p-col-12 p-md-3">
                new name
              </label>
              <div className="p-col-12 p-md-9">
                <span className="p-fluid">
                  <InputText name="name" value={priceList.name ?? ''} onChange={e => handleInputChange(e)} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceListEditComponent;
