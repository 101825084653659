import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService, TwoDialog, UsersService} from 'two-app-ui';
import {CompanyContact, Contact, ContactRole, QueryParameter} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import ContactsService from '../../services/ContactsService';
import CompanyContactService from '../../services/CompanyContactService';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {contactRoles} from '../../config/values';

interface Props {
  showDialog: boolean;
  onHide: (runId?: number) => void;
  contactId: string | undefined;
  companyId: string;
}

interface State {
  loading: boolean;
  contact: Contact | undefined;
  companyContact: CompanyContact | undefined;
}

class AddEditContactDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  contactsService: ContactsService | null = null;
  usersService: UsersService | null = null;
  employeesService: CompanyContactService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      contact: undefined,
      companyContact: undefined,
    };

    this.toast = React.createRef();

    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.employeesService = this.context.employeesService;
    this.usersService = this.context.usersService;
    this.toastService = this.context.toastService;
  }

  setContact() {
    const id = this.props.contactId;
    if (id) {
      this.loadContact(id);
    } else {
      const newContact: Contact = {
        deleted: false,
        email: '',
        first_name: '',
        id: '',
        last_name: '',
        title: '',
        updated_at: new Date(),
      };
      const newCompanyContact: CompanyContact = {
        company_id: this.props.companyId,
        contact_id: this.props.contactId ?? '',
        in_role: 'admin',
      };
      this.setState({contact: newContact, companyContact: newCompanyContact});
    }
  }

  hideDialog() {
    this.setState({contact: undefined, loading: false});
    this.props.onHide();
  }

  loadContact(id: string) {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
      calculate_values: false,
    };

    this.contactsService
      ?.getContacts(params)
      .then(data => {
        const dataRecords = (data?.records as Contact[]) ?? [];
        const contact = dataRecords[0];

        const comContact = contact.company_contacts?.find(con => con.company_id === this.props.companyId);

        this.setState({
          contact: contact,
          companyContact: comContact,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const contact = this.state.contact;
    if (contact) {
      if (contact.id) {
        this.updateContact(contact);
      } else {
        this.createContact(contact);
      }
    }
  }

  handleRoleChange(e: DropdownChangeParams) {
    if (this.state.companyContact) {
      const updatedComCont = {
        ...this.state.companyContact,
        in_role: e.value,
      };
      this.setState({companyContact: updatedComCont});
    }
  }

  async createContact(contact: Contact) {
    this.setState({loading: true});

    return this.contactsService
      ?.createContact(contact)
      .then(createdContact => {
        this.createCompanyContact(createdContact);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async createCompanyContact(contact: Contact) {
    const companyContact: CompanyContact = {
      company_id: this.props.companyId,
      contact_id: contact.id,
      in_role: this.state.companyContact?.in_role ?? ('sales-rep' as ContactRole), //todo 'as ContactRole' will be removed after fixing it in the two-core
    };

    return this.employeesService
      ?.createCompanyContact(companyContact)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Contact created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.contactUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateContact(contact: Contact) {
    this.setState({loading: true});

    return this.contactsService
      ?.updateContact(contact?.id?.toString() ?? '', contact)
      .then(() => {
        if (this.state.companyContact) {
          this.updateCompanyContact(this.state.companyContact);
        }
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateCompanyContact(companyContact: CompanyContact) {
    return this.employeesService
      ?.updateCompanyContact(companyContact)
      .then(() => {
        this.toastService?.showSuccess(this.toast, 'Contact updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.contactUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, contact update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const contact = this.state.contact;

    const updatedContact = {
      ...contact,
      [e.target.name]: e.target.value,
    };

    this.setState({contact: updatedContact as Contact});
  }

  render() {
    const {contact, loading} = this.state;

    return (
      <TwoDialog
        headerTitle={contact?.id ? 'Edit Contact' : 'Add Contact'}
        showDialog={this.props.showDialog}
        loading={loading}
        onHide={() => this.hideDialog()}
        onShow={() => this.setContact()}
        onSave={this.save}
        width={70}
      >
        <div className="contact-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="title" className="p-col-12 p-lg-3">
                  title
                </label>
                <div className="p-col-12 p-lg-9">
                  <span className="p-fluid">
                    <InputText
                      name="title"
                      value={contact?.title ?? ''}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="first_name" className="p-col-12 p-lg-3">
                  first name
                </label>
                <div className="p-col-12 p-lg-9">
                  <span className="p-fluid">
                    <InputText
                      name="first_name"
                      value={contact?.first_name ?? ''}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="last_name" className="p-col-12 p-lg-3">
                  last name
                </label>
                <div className="p-col-12 p-lg-9">
                  <span className="p-fluid">
                    <InputText
                      name="last_name"
                      value={contact?.last_name ?? ''}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="email" className="p-col-12 p-lg-3">
                  email
                </label>
                <div className="p-col-12 p-lg-9">
                  <span className="p-fluid">
                    <InputText
                      name="email"
                      value={contact?.email ?? ''}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="phone_number" className="p-col-12 p-lg-3">
                  phone number
                </label>
                <div className="p-col-12 p-lg-9">
                  <span className="p-fluid">
                    <InputText
                      name="phone_number"
                      value={contact?.phone_number ?? ''}
                      onChange={e => {
                        this.handleChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-4">
              <div className="p-grid p-ai-center">
                <label htmlFor="role" className="p-col-12 p-lg-3">
                  role
                </label>
                <span className="p-fluid p-col-12 p-lg-9">
                  <Dropdown
                    name="role"
                    value={this.state.companyContact?.in_role}
                    options={contactRoles}
                    onChange={e => this.handleRoleChange(e)}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Toast ref={this.toast} />
      </TwoDialog>
    );
  }
}
export default AddEditContactDialog;
