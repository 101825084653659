import React from 'react';
import {PriceList, PriceListPatch} from 'two-core';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import PriceListsService from '../../services/PriceListsService';
import {Toast} from 'primereact/toast';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import PriceListEditComponent from './PriceListEditComponent';
import {Subscription} from 'rxjs';
import {messages} from '../../config/messages';

interface Props {
  priceList: PriceList;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  priceList: PriceList;
}

class PriceListRenameDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  priceListsService: PriceListsService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;
  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      priceList: this.props.priceList,
    };
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.priceListsService = this.context.priceListsService;
    this.toastService = this.context.toastService;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderFooter = this.renderFooter.bind(this);
    this.closePriceList = this.closePriceList.bind(this);
    this.setPriceList = this.setPriceList.bind(this);
    this.savePriceList = this.savePriceList.bind(this);
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const priceList = this.state.priceList;
    const updatedPriceList = {
      ...priceList,
      [e.target.name]: e.target.value,
    };
    this.setState({priceList: updatedPriceList});
  }

  closePriceList() {
    this.setState({
      loading: false,
      priceList: {
        name: '',
        stage: 'Draft',
      },
    });
    this.props.onHide();
  }

  savePriceList() {
    this.setState({
      loading: true,
    });

    const renamedPriceList: PriceListPatch = {
      name: this.state.priceList.name,
    };

    this.priceListsService
      ?.updatePriceList(this.state.priceList.id ?? 0, renamedPriceList)
      .then(() => {
        MessageService.sendMessage(messages.priceListUpdated);
        this.toastService?.showSuccess(this.toast, `Price list ${this.state.priceList.name} was updated successfully.`);
        this.closePriceList();
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          `Sorry, ${this.state.priceList.name} update failed, please try again.`
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  renderFooter() {
    return (
      <div className="p-d-flex p-my-4 p-justify-end">
        <Button
          label="cancel"
          className="p-mr-2 p-button-text"
          onClick={() => {
            this.closePriceList();
          }}
        />
        <Button
          label="save"
          className="p-mr-2"
          onClick={() => {
            this.savePriceList();
          }}
          autoFocus
        />
      </div>
    );
  }

  setPriceList() {
    this.setState({
      priceList: this.props.priceList,
    });
  }

  render() {
    return (
      <>
        <Dialog
          onHide={this.closePriceList}
          header="Rename Price List"
          visible={this.props.showDialog}
          style={{width: '90%'}}
          modal
          className="p-fluid"
          onShow={this.setPriceList}
          footer={this.renderFooter}
        >
          {!this.state.loading ? (
            <PriceListEditComponent priceList={this.state.priceList} handleInputChange={this.handleInputChange} />
          ) : (
            <div className="p-d-flex p-flex-row p-ai-center">
              {' '}
              <ProgressSpinner />
            </div>
          )}
        </Dialog>
      </>
    );
  }
}

export default PriceListRenameDialog;
