import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {ProgressSpinner} from 'primereact/progressspinner';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Location} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import LocationsService from '../../services/LocationsService';
import {companyLocationTypes} from '../../config/values';
import {InputSwitch} from 'primereact/inputswitch';

interface Props {
  showDialog: boolean;
  onHide: (runId?: number) => void;
  toast: React.RefObject<Toast>;
  locationId: number | undefined;
  companyId: string;
}

interface State {
  loading: boolean;
  location: Location | undefined;
}

class AddEditLocationDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      location: undefined,
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.toastService = this.context.toastService;
  }

  setLocation() {
    const id = this.props.locationId;
    if (id) {
      this.loadLocation(id);
    } else {
      const emptyLocation: Location = {
        type: 'factory',
        name: '',
        dealership_id: this.props.companyId,
        used_for_fitting: false,
        address: {
          street: '',
          suburb: '',
          state: '',
          state_short: '',
          postCode: '',
          country: '',
          phoneNumber: '',
          lat: 0,
          long: 0,
        },
        state_id: 'QLD',
      };
      this.setState({location: emptyLocation});
    }
  }

  hideDialog() {
    this.setState({location: undefined, loading: false});
    this.props.onHide();
  }

  loadLocation(id: number) {
    this.setState({loading: true});

    this.locationsService
      ?.getLocation(id)
      .then(data => {
        const location = data?.body as Location;

        this.setState({
          location: location,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, location load failed, please try again.');
        this.setState({loading: false});
        console.error(error);
      });
  }

  async save() {
    const location = this.state.location;
    if (location) {
      if (location.id) {
        this.updateLocation(location);
      } else {
        this.createLocation(location);
      }
    }
  }

  async createLocation(location: Location) {
    this.setState({loading: true});

    return this.locationsService
      ?.createLocation(location)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Location created successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, location create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  async updateLocation(location: Location) {
    this.setState({loading: true});

    return this.locationsService
      ?.updateLocation(location?.id?.toString() ?? '', location)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Location updated successfully.');
        this.hideDialog();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, location update failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  setValue(title: string, value: string | boolean) {
    const location = this.state.location;
    if (location) {
      const updatedLocation = {
        ...location,
        [title]: value,
      };
      this.setState({location: updatedLocation});
    }
  }

  setAddressValue(title: string, value: string) {
    const location = this.state.location;
    if (location) {
      const address = location.address;
      const updatedAddress = {...address, [title]: value};
      const updatedLocation = {
        ...location,
        address: updatedAddress,
      };
      this.setState({location: updatedLocation});
    }
  }

  handleLocationChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
    const location = this.state.location;

    const updatedLocation = {
      ...location,
      [e.target.name]: e.target.value,
    };

    this.setState({location: updatedLocation as Location});
  }

  renderFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.hideDialog()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    );
  }

  render() {
    const {location, loading} = this.state;
    const address = location?.address;

    return (
      <Dialog
        header={location?.id ? 'Edit Location' : 'Add Location'}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '50%'}}
        modal
        onHide={() => this.hideDialog()}
        onShow={() => this.setLocation()}
      >
        {!loading ? (
          <div className="p-grid">
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="name" className="p-col-1">
                name
              </label>
              <div className="p-col-5">
                <span className="p-fluid">
                  <InputText
                    value={location?.name ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setValue('name', value);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="name" className="p-col-1">
                type
              </label>
              <div className="p-col-5">
                <span className="p-fluid">
                  <Dropdown
                    options={companyLocationTypes}
                    value={location?.type ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setValue('type', value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="suburb" className="p-col-1">
                suburb
              </label>
              <div className="p-col-5">
                <span className="p-fluid">
                  <InputText
                    value={address?.suburb ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setAddressValue('suburb', value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="street" className="p-col-1">
                street
              </label>
              <div className="p-col-11">
                <span className="p-fluid">
                  <InputText
                    value={address?.street ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setAddressValue('street', value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="postCode" className="p-col-1">
                post code
              </label>
              <div className="p-col-5">
                <span className="p-fluid">
                  <InputText
                    value={address?.postCode ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setAddressValue('postCode', value);
                    }}
                  />
                </span>
              </div>
              <label htmlFor="state" className="p-col-1">
                state
              </label>
              <div className="p-col-5">
                <span className="p-fluid">
                  <Dropdown
                    options={['ACT', 'NSW', 'QLD', 'VIC', 'SA', 'WA']}
                    value={address?.state ?? ''}
                    onChange={e => {
                      const value = e.target.value;
                      this.setAddressValue('state', value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="instructions" className="p-col-1 p-p-0">
                instructions
              </label>
              <div className="p-col-11">
                <span className="p-fluid">
                  <InputTextarea
                    name={'instructions'}
                    value={location?.instructions ?? ''}
                    onChange={e => this.handleLocationChange(e)}
                    rows={5}
                  />
                </span>
              </div>
            </div>
            <div className="p-d-flex p-ai-center p-col-12">
              <label htmlFor="used_for_fitting" className="p-col-1 p-p-0">
                used for fitting
              </label>
              <div className="p-col-11">
                <span className="p-fluid">
                  <InputSwitch
                    name="used_for_fitting"
                    checked={location?.used_for_fitting ?? false}
                    onChange={e => {
                      const value = e.target.value;
                      this.setValue('used_for_fitting', value);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default AddEditLocationDialog;
