export const config = () => {
  const companyService = process.env.REACT_APP_CUSTOMER_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const orderService = process.env.REACT_APP_ORDER_SERVICE;
  const freightService = process.env.REACT_APP_FREIGHT_SERVICE;
  const invoicesService = process.env.REACT_APP_INVOICE_SERVICE;
  const invoiceService = process.env.REACT_APP_INVOICE_SERVICE;
  const productsService = process.env.REACT_APP_PRODUCT_SERVICE;

  return {
    system: {
      stop_typing_detection: 1000,
    },
    endpoints: {
      companies: companyService + 'companies',
      contacts: companyService + 'contacts',
      companyContact: companyService + 'company-contacts',
      tles: tleService + 'tles',
      orders: orderService + 'orders',
      locations: freightService + 'locations',
      states: freightService + 'states',
      invoices: invoicesService + 'invoices',
      priceLists: invoiceService + 'price-lists',
      priceListDefinitionRevisions: invoiceService + 'price-definition-revisions',
      priceListVersions: invoiceService + 'price-list-versions',
      productCostings: invoiceService + 'price-list-product-costings',
      products: productsService + 'product-definition-revisions',
    },
  };
};
export default config;
