import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {messages} from '../../config/messages';
import {DropdownOption, PriceList, PriceListVersion, QueryParameter} from 'two-core';
import PriceListDefinitionRevisionsService from '../../services/PriceListDefinitionRevisionsService';
import {Dropdown} from 'primereact/dropdown';
import PriceListsService from '../../services/PriceListsService';
import PriceListVersionService from '../../services/PriceListVersionService';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import PriceListProductCostingsService from '../../services/PriceListsProductCostingsService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  selectedPriceList: PriceList;
  currentVersion: PriceListVersion;
}

interface State {
  loading: boolean;
  copyFromVersionID: number;
  priceLists: PriceList[];
  copyPriceList: PriceList;
}

class CopyPriceListVersionDialog extends React.Component<RouteComponentProps<{}> & Props, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  priceListDefinitionRevisionsService: PriceListDefinitionRevisionsService | null = null;
  priceListsService: PriceListsService | null = null;
  priceListVersionService: PriceListVersionService | null = null;
  priceListProductCostingsService: PriceListProductCostingsService | null = null;

  constructor(props: RouteComponentProps<{}> & Props) {
    super(props);
    this.state = {
      loading: false,
      copyFromVersionID: 0,
      priceLists: [],
      copyPriceList: {
        name: '',
        stage: 'Draft',
      },
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getAllActivePriceListsOptions = this.getAllActivePriceListsOptions.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.priceListProductCostingsService = this.context.priceListProductCostingsService;
    this.priceListsService = this.context.priceListsService;
    this.priceListVersionService = this.context.priceListVersionsService;
    this.priceListDefinitionRevisionsService = this.context.priceListDefinitionRevisionsService;

    this.loadData();
  }

  loadData() {
    this.setState({loading: true, copyPriceList: this.props.selectedPriceList});
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'deleted',
        value: false,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const dataRecords = (data.records as PriceList[]) ?? [];

        this.setState({
          priceLists: dataRecords,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  hideDialog() {
    this.setState({
      copyFromVersionID: 0,
      loading: false,
    });
    this.props.onHide();
  }

  getAllActivePriceListsOptions() {
    const options: DropdownOption[] = [];
    this.state.priceLists.forEach(priceList => {
      if (priceList.deleted === false) {
        options.push({
          label: priceList.id === this.props.selectedPriceList.id ? 'This price list' : priceList.name,
          value: priceList.id as number,
        });
      }
    });

    return options;
  }

  getVersionsOptions(priceList: PriceList) {
    const options: DropdownOption[] = [];
    if (priceList.price_list_versions) {
      priceList.price_list_versions.forEach(version => {
        if (version !== null) {
          options.push({
            label: version.index + ' (' + version.stage + ')',
            value: version.id as number,
          });
        }
      });
    }

    return options;
  }

  async save() {
    this.setState({
      loading: true,
    });
    const {copyFromVersionID} = this.state;
    const {currentVersion} = this.props;
    if (currentVersion.id) {
      this.priceListProductCostingsService?.copyProductCosting(copyFromVersionID, currentVersion.id);
    }
    this.props.onHide();
    MessageService.sendMessage(messages.priceListUpdated);
  }

  renderFooter() {
    return !this.state.loading ? (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.props.onHide()} />
        <Button
          label="Save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    const {loading, copyPriceList, copyFromVersionID} = this.state;
    return (
      <Dialog
        header={'Copy from another version to ' + this.props.selectedPriceList.name}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '80%'}}
        modal
        onHide={this.hideDialog}
        onShow={this.loadData}
      >
        {!loading ? (
          <>
            <div className="p-grid">
              <label htmlFor="price-list" className="p-col-1">
                from
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <Dropdown
                    options={this.getAllActivePriceListsOptions()}
                    value={copyPriceList.id ?? this.props.selectedPriceList.id}
                    onChange={e => {
                      const pL = this.state.priceLists.find(list => list.id === e.target.value);
                      this.setState({
                        copyPriceList: pL ? pL : this.state.copyPriceList,
                      });
                    }}
                  />
                </span>
              </div>
              <label htmlFor="version" className="p-col-1">
                and version
              </label>
              <div className="p-col-3">
                <span className="p-fluid">
                  <Dropdown
                    options={copyPriceList.id && copyPriceList.id > 0 ? this.getVersionsOptions(copyPriceList) : []}
                    value={copyFromVersionID ?? ''}
                    onChange={e => {
                      this.setState({
                        copyFromVersionID: e.target.value,
                      });
                    }}
                  />
                </span>
              </div>
            </div>
          </>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default withRouter(CopyPriceListVersionDialog);
