import React from 'react';
import {Toast} from 'primereact/toast';
import {AppContext, ToastService, TwoDialog, UsersService} from 'two-app-ui';
import {ProgressSpinner} from 'primereact/progressspinner';
import {MessageService} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {QueryParameter, Company, User, State as TwoState, Address, CompanyPatch} from 'two-core';
import CompanyEditComponent from './CompanyEditComponent';
import {DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import StatesService from '../../services/StatesService';
import {MultiSelectChangeParams} from 'primereact/multiselect';

interface Props {
  company: Company;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  loadingCompany: boolean;
  company?: Company;
  companyPatch: CompanyPatch;
  parentCompanies: Company[];
  users: User[];
  states: TwoState[];
  allCompanies: Company[];
  selectedFitForIds: string[];
}

class CompanyEditDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;
  statesService: StatesService | null = null;

  toast: React.RefObject<Toast>;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      loadingCompany: false,
      companyPatch: {},
      parentCompanies: [],
      users: [],
      states: [],
      allCompanies: [],
      selectedFitForIds: [],
    };

    this.toast = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveCompany = this.saveCompany.bind(this);
    this.closeCompany = this.closeCompany.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.handleFitForChange = this.handleFitForChange.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.usersService = this.context.usersService;
    this.statesService = this.context.statesService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.companyUpdated) {
        this.reloadData();
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  setCompany() {
    const selectedFitForIds = this.props.company.fit_for?.map(fitForCompany => fitForCompany.id!) ?? [];
    this.setState({
      company: this.props.company,
      selectedFitForIds: selectedFitForIds,
    });
    this.loadData();
  }

  reloadData() {
    const filters: string[] = [];
    this.setState({loading: true});
    filters.push(
      JSON.stringify({
        field: 'id',
        value: this.props.company.id,
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };
    this.companiesService?.getCompanies(params).then(data => {
      const company = (data.records as Company[])[0];
      const selectedFitForIds = company.fit_for?.map(fitForCompany => fitForCompany.id!) ?? [];

      this.setState({
        company: company,
        selectedFitForIds: selectedFitForIds,
      });
    });
  }

  async loadData() {
    this.loadUsers();
    this.loadCompanies();
    this.loadStates();
  }

  async loadUsers() {
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    //TODO: add filter to load only sales reps => users with role sales_rep in made2manage

    this.usersService
      ?.getUsers(params)
      .then(data => {
        const users = data.records as User[];

        this.setState({
          users: users,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, user records load failed, please try again.');
        console.error(error);
      });
  }

  async loadCompanies() {
    const params: QueryParameter = {
      filters: [
        JSON.stringify({
          field: 'is_distributor',
          value: true,
        }),
      ],
      aggregate: true,
    };
    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const companies = data.records as Company[];

        this.setState({
          parentCompanies: companies,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, parent company records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });

    this.companiesService
      ?.getCompanies({
        aggregate: false,
        orderBys: [JSON.stringify({field: 'name', direction: 'ASC'})],
      })
      .then(data => {
        const companies = data.records as Company[];

        this.setState({
          allCompanies: companies,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, company records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async loadStates() {
    const params: QueryParameter = {
      aggregate: true,
    };
    this.statesService
      ?.getStates(params)
      .then(data => {
        const states = data.records as TwoState[];

        this.setState({
          states: states,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, state records load failed, please try again.');
        console.error(error);
      });
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {companyPatch} = this.state;
    let value = e.target.value;
    const name = e.target.name;
    if (e.target.name === 'fitting_types') {
      value = e.target.value && e.target.value.length > 0 ? e.target.value.join(',') : '';
    }
    const updatedCompany = {
      ...companyPatch,
      [name]: value,
    };
    this.setState({companyPatch: updatedCompany});
  }

  handleFitForChange(e: MultiSelectChangeParams) {
    const updatedCompany = {
      ...this.state.companyPatch,
      fit_for_ids: e.target.value,
    };
    this.setState({
      companyPatch: updatedCompany,
      selectedFitForIds: e.target.value,
    });
  }

  setBillingAddress(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {company, companyPatch} = this.state;
    const address: Address = companyPatch?.billing_address ??
      company?.billing_address ?? {
        state: '',
        street: '',
        state_short: '',
        suburb: '',
        postCode: '',
        country: '',
        phoneNumber: '',
        lat: 0,
        long: 0,
      };

    const updatedAddress: Address = {
      ...address,
      [e.target.name]: e.target.value,
    };

    const updatedCompany = {
      ...companyPatch,
      billing_address: updatedAddress,
    };
    this.setState({companyPatch: updatedCompany});
  }

  saveCompany() {
    this.setState({loading: true});
    this.companiesService
      ?.updateCompany(this.state.company!.id ?? '', this.state.companyPatch)
      .then(() => {
        MessageService.sendMessage(messages.companyUpdated);
        this.toastService?.showSuccess(
          this.toast,
          `Company ${this.state.company!.trading_as ?? this.state.company!.name} updated successfully.`
        );
        this.closeCompany();
      })
      .catch(error => {
        this.toastService?.showError(
          this.toast,
          `Sorry, ${this.state.company!.trading_as ?? this.state.company!.name} update failed, please try again.`
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  closeCompany() {
    this.setState({
      loading: false,
      company: undefined,
      companyPatch: {},
    });
    this.props.onHide();
  }

  render() {
    return (
      <>
        <TwoDialog
          headerTitle={'Edit Customer'}
          showDialog={this.props.showDialog}
          className="p-fluid p-col-11 p-lg-8"
          onHide={this.closeCompany}
          onShow={this.setCompany}
          onSave={this.saveCompany}
          loading={this.state.loading}
        >
          {!this.state.loading ? (
            <CompanyEditComponent
              company={this.state.company!}
              companyPatch={this.state.companyPatch}
              handleInputChange={this.handleInputChange}
              users={this.state.users}
              companies={this.state.allCompanies}
              states={this.state.states}
              handleBillingAddressChange={e => this.setBillingAddress(e)}
              handleFitForChange={this.handleFitForChange}
              selectedFitForIds={this.state.selectedFitForIds}
            />
          ) : (
            <div className="p-ai-center p-col-11 p-lg-8">
              <ProgressSpinner />
            </div>
          )}
        </TwoDialog>
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default CompanyEditDialog;
