import React from 'react';
import {Contact} from 'two-core';

interface Props {
  contact: Contact;
}

class ContactDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const contact = this.props.contact;

    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="first_name" className="p-col-12 p-md-3">
            first name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{contact.first_name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="last_name" className="p-col-12 p-md-3">
            last name
          </label>
          <div className="p-col-12 p-md-9">
            <span>{contact.last_name ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="email" className="p-col-12 p-md-3">
            email
          </label>
          <div className="p-col-12 p-md-9">
            <span>{contact.email ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="phone_number" className="p-col-12 p-md-3">
            phone number
          </label>
          <div className="p-col-12 p-md-9">
            <span>{contact.phone_number ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="title" className="p-col-12 p-md-3">
            title
          </label>
          <div className="p-col-12 p-md-9">
            <span>{contact.title ?? ''}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactDetail;
