import {PriceListPickListValue, PriceListProductFreightSurcharge, PriceListProductPrice} from 'two-core';
import React from 'react';
import {InputText} from 'primereact/inputtext';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Button} from 'primereact/button';

interface Props {
  item: PriceListProductFreightSurcharge | PriceListProductPrice;
  productCostingIndex: number;
  handleValueChange: (value: number | PriceListPickListValue[], productCostingIndex: number) => void;
}

interface State {
  item: PriceListProductFreightSurcharge | PriceListProductPrice | undefined;
}

class EditPriceListProductFreightComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      item: undefined,
    };

    this.loadData = this.loadData.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      item: this.props.item,
    });
  }

  handleValueChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams, valueIndex?: number, name?: string) {
    const {item} = this.state;
    if (item) {
      if (Array.isArray(item?.value) && valueIndex !== undefined) {
        name === 'key'
          ? (item.value[valueIndex].key = e.target.value)
          : (item.value[valueIndex].value = e.target.value);
      } else {
        item.value = e.target.value;
      }

      this.props.handleValueChange(item.value, this.props.productCostingIndex);
    }
  }

  handleRemoveItem(valueIndex: number) {
    const {item} = this.state;
    if (item) {
      if (Array.isArray(item.value) && valueIndex !== undefined) {
        item.value.splice(valueIndex, 1);
      }

      this.props.handleValueChange(item.value, this.props.productCostingIndex);
    }
  }

  handleAddItem() {
    const {item} = this.state;
    if (item) {
      if (Array.isArray(item.value)) {
        const values = item.value as unknown as PriceListPickListValue[];

        values.push({
          key: '',
          value: 0,
        });

        item.value = values;
      }

      this.props.handleValueChange(item.value, this.props.productCostingIndex);
    }
  }

  isAnyItemEmpty() {
    let result = false;
    const {item} = this.state;
    if (item) {
      if (Array.isArray(item.value)) {
        const values = item.value as unknown as PriceListPickListValue[];
        values.forEach(value => {
          if (value.key === '' || value.key === undefined) {
            result = true;
          }
        });
      }
    } else {
      result = true;
    }

    return result;
  }

  render() {
    const {item} = this.state;
    return (
      <>
        {item && (
          <div key={item.sku}>
            <div className="p-d-flex p-ai-center p-mt-2">
              <label htmlFor="stage" className="p-col-12 p-md-3">
                items ({item.sku})
              </label>
              <div className="p-col-12 p-md-9">
                {item.value_type === 'picklist' ? (
                  <div>
                    {Array.isArray(item.value) &&
                      item.value.map((value, index) => {
                        return (
                          <div key={index}>
                            <div className={'p-fluid p-grid p-mt-2'}>
                              <div className={'p-col-4'}>
                                <span className="p-float-label">
                                  <InputText
                                    name="key"
                                    value={value.key ?? ''}
                                    onChange={e => this.handleValueChange(e, index, 'key')}
                                  />
                                  <label htmlFor="key">key</label>
                                </span>
                              </div>
                              <div className={'p-col-4'}>
                                <span className="p-float-label">
                                  <InputText
                                    name="value"
                                    value={value.value ?? ''}
                                    onChange={e => this.handleValueChange(e, index)}
                                  />
                                  <label htmlFor="value">value</label>
                                </span>
                              </div>
                              {Array.isArray(item.value) &&
                                item.value.length > 1 &&
                                index + 1 === item.value.length && (
                                  <div className={'p-col-1'}>
                                    <Button
                                      label="X"
                                      className={'p-mr-2'}
                                      onClick={() => {
                                        this.handleRemoveItem(index);
                                      }}
                                      title={'Remove this item'}
                                    />
                                  </div>
                                )}
                            </div>
                            {!this.isAnyItemEmpty() && Array.isArray(item.value) && index + 1 === item.value.length && (
                              <div className={'p-fluid p-grid p-mt-2'}>
                                <div className={'p-col-2'}>
                                  <Button
                                    label="add"
                                    className={'p-mr-2'}
                                    onClick={() => {
                                      this.handleAddItem();
                                    }}
                                    title={'Remove this item'}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <span className="p-float-label">
                    <InputText
                      name="value"
                      value={(item.value as number) ?? ''}
                      onChange={e => this.handleValueChange(e)}
                    />
                    <label htmlFor="value">Value</label>
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default EditPriceListProductFreightComponent;
