import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {MapOf, PriceList, PriceListVersion, QueryParameter, User} from 'two-core';
import {
  AppContext,
  MessageService,
  ToastService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  UsersService,
} from 'two-app-ui';
import PriceListsService from '../../services/PriceListsService';
import TleService from '../../services/TleService';
import {Subscription} from 'rxjs';
import {Toast} from 'primereact/toast';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import PriceListRenameDialog from './PriceListRenameDialog';
import {messages} from '../../config/messages';
import {ProgressSpinner} from 'primereact/progressspinner';
import PriceListVersions from '../PriceListVersion/PriceListVersions';
import AddPriceListVersionDialog from '../PriceListVersion/AddPriceListVersionDialog';
import PriceListVersionService from '../../services/PriceListVersionService';
import PriceListCustomers from './PriceListCustomers';

interface RouteProps {
  id: string;
}

interface State {
  loadingPriceList: boolean;
  priceList: PriceList | undefined;
  showRenameDialog: boolean;
  showAddVersionDialog: boolean;
  loadingSecondaryView: boolean;
  usersMap: MapOf<User>;
}

class PriceListComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  priceListsService: PriceListsService | null = null;
  priceListVersionsService: PriceListVersionService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;
  usersService: UsersService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingPriceList: false,
      priceList: undefined,
      showRenameDialog: false,
      showAddVersionDialog: false,
      loadingSecondaryView: false,
      usersMap: {},
    };

    this.toast = React.createRef();

    this.hideRenameDialog = this.hideRenameDialog.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadData = this.loadData.bind(this);
    this.hasDraftVersion = this.hasDraftVersion.bind(this);
    this.releaseDraft = this.releaseDraft.bind(this);
  }

  componentDidMount() {
    this.priceListsService = this.context.priceListsService;
    this.priceListVersionsService = this.context.priceListVersionsService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.priceListUpdated) {
        this.loadPriceList();
      } else if (message === messages.priceListVersionCreated) {
        this.loadLastPriceLists();
      }
    });

    this.loadData();
  }

  loadLastPriceLists() {
    this.setState({loadingPriceList: true});

    const sortBy: string[] | undefined = [];

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: [],
      orderBys: sortBy,
    };

    this.priceListVersionsService
      ?.getPriceListVersions(params)
      .then(data => {
        const version = (data.records as PriceListVersion[])[0];
        this.setState({
          loadingPriceList: false,
        });
        this.props.history.push('/price-list-version/' + version.id);
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loadingPriceList: false});
      });
  }

  async loadData() {
    await this.loadUsers();
    this.loadPriceList();
  }
  async loadUsers() {
    const userMap: MapOf<User> = {};

    const params: QueryParameter = {
      aggregate: false,
    };
    const data = await this.usersService?.getUsers(params);
    for (const user of data?.records as User[]) {
      userMap[user.id!] = user;
    }

    this.setState({
      usersMap: userMap,
    });
  }

  loadPriceList() {
    this.setState({loadingPriceList: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'id',
        value: this.props.match.params.id,
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const priceList = (data.records as PriceList[])[0];
        this.setState({priceList: priceList, loadingPriceList: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, price list loading failed, please try again.');
        console.error(error);
        this.setState({loadingPriceList: false});
      });
  }

  getActions(): TwoAction[] {
    const menuActions: TwoAction[] = [];
    const renameAction = {
      icon: ['far', 'pencil'] as IconProp,
      label: 'Rename',
      main: true,
      action: () => {
        this.showRenameDialog();
      },
    };
    menuActions.push(renameAction);

    if (!this.hasDraftVersion()) {
      const addVersionAction = {
        icon: ['far', 'plus'] as IconProp,
        label: 'Add New Version',
        action: () => {
          this.showAddVersionDialog();
        },
      };
      menuActions.push(addVersionAction);
    } else {
      const releaseDraftAction = {
        label: 'Release Draft',
        action: () => {
          this.releaseDraft();
        },
      };
      menuActions.push(releaseDraftAction);
    }

    return menuActions;
  }

  releaseDraft() {
    this.setState({loadingPriceList: true, loadingSecondaryView: true});
    const {priceList} = this.state;

    if (priceList && priceList.price_list_versions) {
      const version = priceList.price_list_versions.find(version => version.stage === 'Draft');
      if (version?.id) {
        this.priceListVersionsService
          ?.releasePriceListVersion(version.id)
          .then(() => {
            this.setState({
              loadingPriceList: false,
              loadingSecondaryView: false,
            });
            this.loadData();
          })
          .catch(error => {
            this.toastService?.showError(this.toast, 'Sorry, release draft version failed, please try again.');
            console.error(error);
            this.setState({
              loadingPriceList: false,
              loadingSecondaryView: false,
            });
          });
      }
    } else {
      this.setState({
        loadingPriceList: false,
        loadingSecondaryView: false,
      });
    }
  }

  hasDraftVersion() {
    const versions = this.state.priceList?.price_list_versions;
    if (versions && versions.length > 0 && versions[0] !== null) {
      const draftVersion = versions?.filter(version => version.stage === 'Draft');
      return draftVersion !== undefined && draftVersion.length > 0;
    }

    return false;
  }

  showRenameDialog() {
    this.setState({
      showRenameDialog: true,
    });
  }

  hideRenameDialog() {
    this.setState({
      showRenameDialog: false,
    });
  }

  showAddVersionDialog() {
    this.setState({
      showAddVersionDialog: true,
    });
  }

  hideAddVersionDialog() {
    this.setState({
      showAddVersionDialog: false,
    });
  }

  render() {
    const {priceList, showRenameDialog, usersMap} = this.state;
    return priceList ? (
      <>
        <TwoEntityComponent
          title={priceList.name + ' - ' + priceList.stage}
          actions={this.getActions()}
          hasDetail={false}
          showDetail={false}
        >
          <TwoEntityPanel isPrimary={true} />
          {/*This is primary button (show detail), but in Price List this is empty, no detail is needed*/}
          <TwoEntityPanel label="Versions" icon={['far', 'list']} tooltip="Versions">
            {!this.state.loadingSecondaryView ? (
              <PriceListVersions priceList={priceList} usersMap={usersMap} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Customers" icon={['far', 'user-friends']} tooltip="Customers">
            {!this.state.loadingSecondaryView ? (
              <PriceListCustomers customers={priceList.customers ?? []} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <PriceListRenameDialog priceList={priceList} showDialog={showRenameDialog} onHide={this.hideRenameDialog} />
        <AddPriceListVersionDialog
          showDialog={this.state.showAddVersionDialog}
          onHide={() => {
            this.hideAddVersionDialog();
          }}
          toast={this.toast}
          selectedPriceList={priceList}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(PriceListComponent);
