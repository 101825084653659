import React from 'react';
import {PriceListProductCosting, PriceListVersion, PriceListPickListValue} from 'two-core';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Accordion, AccordionTab, AccordionTabProps} from 'primereact/accordion';
import EditPriceListProductPriceItemComponent from './EditPriceListProductPriceItemComponent';
import EditPriceListProductFreightComponent from './EditPriceListProductFreightComponent';
import PriceListProductCostingsService from '../../services/PriceListsProductCostingsService';
import {messages} from '../../config/messages';
import './EditPriceListVersionDialog.scss';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  productCostings: PriceListProductCosting[];
  priceListName: string;
  priceListVersion: PriceListVersion;
}

interface State {
  loading: boolean;
  productCostings: PriceListProductCosting[];
  updatedCostings: number[];
}

class EditPriceListVersionDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  priceListProductCostingService: PriceListProductCostingsService | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      productCostings: this.props.productCostings,
      updatedCostings: [],
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.setUpdated = this.setUpdated.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.save = this.save.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSurchargeValueChange = this.handleSurchargeValueChange.bind(this);
    this.handleDiscountValueChange = this.handleDiscountValueChange.bind(this);
    this.handleDiscountTitleChange = this.handleDiscountTitleChange.bind(this);
    this.handleSurchargeTitleChange = this.handleSurchargeTitleChange.bind(this);
    this.handleFreightValueChange = this.handleFreightValueChange.bind(this);
    this.handlePriceValueChange = this.handlePriceValueChange.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.priceListProductCostingService = this.context.priceListProductCostingsService;
  }

  hideDialog() {
    this.setState({
      productCostings: [],
      loading: false,
    });
    this.props.onHide();
  }

  loadData() {
    this.setState({
      productCostings: this.props.productCostings,
    });
  }

  save() {
    this.setState({loading: true});
    const promises: (Promise<PriceListProductCosting> | undefined)[] = [];

    this.state.productCostings.forEach(productCosting => {
      promises.push(this.priceListProductCostingService?.updateProductCosting(productCosting));
    });

    Promise.all(promises)
      .then(() => {
        MessageService.sendMessage(messages.priceListVersionCreated);
        this.toastService?.showSuccess(this.props.toast, 'Price List Product Costing were successfully updated.');
        this.setState({
          loading: false,
        });
        this.props.onHide();
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'Sorry, Price List Product Costing update failed, please try again.'
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  handleSurchargeValueChange(
    value: number | PriceListPickListValue[],
    productCostingIndex: number,
    surchargeIndex: number
  ) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.surcharges?.[surchargeIndex]) {
      updatedProductCostings[productCostingIndex].items.products.surcharges![surchargeIndex].value = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  handleFreightValueChange(value: number | PriceListPickListValue[], productCostingIndex: number) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.freight_surcharge) {
      updatedProductCostings[productCostingIndex].items.products.freight_surcharge.value = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  handlePriceValueChange(value: number | PriceListPickListValue[], productCostingIndex: number) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.product_price) {
      updatedProductCostings[productCostingIndex].items.products.product_price.value = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  handleDiscountValueChange(
    value: number | PriceListPickListValue[],
    productCostingIndex: number,
    surchargeIndex: number
  ) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.discounts?.[surchargeIndex]) {
      updatedProductCostings[productCostingIndex].items.products.discounts![surchargeIndex].value = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  handleSurchargeTitleChange(value: string, productCostingIndex: number, surchargeIndex: number) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.surcharges?.[surchargeIndex]) {
      updatedProductCostings[productCostingIndex].items.products.surcharges![surchargeIndex].title = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  handleDiscountTitleChange(value: string, productCostingIndex: number, surchargeIndex: number) {
    const productCostings = this.state.productCostings;
    const updatedProductCostings = productCostings;
    if (updatedProductCostings[productCostingIndex].items.products.discounts?.[surchargeIndex]) {
      updatedProductCostings[productCostingIndex].items.products.discounts![surchargeIndex].title = value;
    }
    this.setState({productCostings: updatedProductCostings});

    this.setUpdated(productCostingIndex);
  }

  setUpdated(productCostingIndex: number) {
    if (!this.state.updatedCostings.some(index => index === productCostingIndex)) {
      this.setState({
        updatedCostings: [...this.state.updatedCostings, productCostingIndex],
      });
    }
  }

  renderFooter() {
    return !this.state.loading ? (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.props.onHide()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    const panelTemplate = (options: AccordionTabProps, label: string, productCostingIndex: number) => {
      return (
        <>
          <div className={'w-100'}>
            <div className="p-grid p-component p-m-0 p-justify-between w-100 p-align-center">
              <span>{label}</span>
              {this.state.updatedCostings.some(index => index === productCostingIndex) && (
                <span className="updated-text">updated</span>
              )}
            </div>
          </div>
        </>
      );
    };
    const {priceListName, priceListVersion} = this.props;
    const {loading, productCostings} = this.state;
    return (
      <Dialog
        header={'Edit Price List ' + priceListName + priceListVersion.index}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '80%'}}
        modal
        onHide={this.hideDialog}
        onShow={this.loadData}
      >
        {!loading ? (
          <div>
            <Accordion multiple activeIndex={[0]}>
              {productCostings &&
                productCostings.map((productCosting: PriceListProductCosting, productCostingIndex: number) => {
                  if (productCosting.items.products) {
                    const product = productCosting.items.products;
                    return (
                      <AccordionTab
                        headerTemplate={op => panelTemplate(op, product.name ?? '', productCostingIndex)}
                        key={product.key}
                      >
                        <EditPriceListProductFreightComponent
                          item={product.product_price}
                          productCostingIndex={productCostingIndex}
                          handleValueChange={this.handlePriceValueChange}
                        />
                        {product.surcharges?.length && (
                          <div>
                            <div className="p-d-flex p-ai-center">
                              <label htmlFor="stage" className="p-col-12 p-md-3">
                                Surcharges
                              </label>
                              <div className="p-col-12 p-md-9">
                                <hr />
                              </div>
                            </div>
                            <EditPriceListProductPriceItemComponent
                              items={product.surcharges}
                              handleValueChange={this.handleSurchargeValueChange}
                              productCostingIndex={productCostingIndex}
                              handleTitleChange={this.handleSurchargeTitleChange}
                            />
                          </div>
                        )}
                        {product.discounts?.length && (
                          <div>
                            <div className="p-d-flex p-ai-center">
                              <label htmlFor="stage" className="p-col-12 p-md-3">
                                Discounts
                              </label>
                              <div className="p-col-12 p-md-9">
                                <hr />
                              </div>
                            </div>
                            <EditPriceListProductPriceItemComponent
                              items={product.discounts}
                              handleValueChange={this.handleDiscountValueChange}
                              handleTitleChange={this.handleDiscountTitleChange}
                              productCostingIndex={productCostingIndex}
                            />
                          </div>
                        )}
                        <div>
                          <div className="p-d-flex p-ai-center">
                            <label htmlFor="stage" className="p-col-12 p-md-3">
                              Freight
                            </label>
                            <div className="p-col-12 p-md-9">
                              <hr />
                            </div>
                          </div>
                          <EditPriceListProductFreightComponent
                            item={product.freight_surcharge}
                            productCostingIndex={productCostingIndex}
                            handleValueChange={this.handleFreightValueChange}
                          />
                        </div>
                      </AccordionTab>
                    );
                  } else {
                    return (
                      <AccordionTab key={productCostingIndex} header={'Not Represented Product'}>
                        no data
                      </AccordionTab>
                    );
                  }
                })}
            </Accordion>
          </div>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}

export default EditPriceListVersionDialog;
