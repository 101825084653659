import React from 'react';
import {Column} from 'primereact/column';
import {Company} from 'two-core';
import {AppColumnMenuBodyTemplate, AppContext, TwoDataTable} from 'two-app-ui';
import CompaniesService from '../../services/CompaniesService';

interface Props {
  company: Company;
}

class CompanyFitForComponent extends React.Component<Props> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      total_items: 0,
    };
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
  }

  render() {
    const nameBodyTemplate = (rowData: Company) => {
      return (
        <AppColumnMenuBodyTemplate
          key={rowData.id}
          rowItemIdentifier={rowData?.id?.toString() ?? ''}
          isDynamicMenuItems={true}
          selectedItems={[]}
        >
          {`${rowData.name} ${rowData.trading_as ?? ''}`}
        </AppColumnMenuBodyTemplate>
      );
    };

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="fit_for_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            id={'price-list-customers-table'}
            rows={25}
            first={0}
            loading={false}
            value={this.props.company.fit_for}
            totalRecords={this.props.company.fit_for?.length}
            activeFilters={{}}
            selectedItems={[]}
          >
            <Column header="Company Name" body={nameBodyTemplate} sortable showFilterMenu={false} />
            <Column header="Trading as" field="trading_as" sortable showFilterMenu={false} />
          </TwoDataTable>
        </div>
      </div>
    );
  }
}

export default CompanyFitForComponent;
