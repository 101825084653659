import React from 'react';
import {
  DataTablePageParams,
  DataTableSortParams,
  DataTableSortOrderType,
  DataTableExpandedRows,
} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {AppContext, ToastService, TwoDataTable} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {QueryParameter, Contact} from 'two-core';
import config from '../../config/config';
import ContactsService from '../../services/ContactsService';
import {NavLink, RouteComponentProps, withRouter} from 'react-router-dom';
import ContactCompanyListComponent from './ContactCompanyListComponent';

interface State {
  loading: boolean;
  items: Contact[];
  totalItems: number;
  activeFilters: {};
  filters: {
    last_name: string;
    phone_number: string;
    email: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
}

class ContactListComponent extends React.Component<RouteComponentProps, State> {
  static contextType = AppContext;
  contactsService: ContactsService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        last_name: '',
        phone_number: '',
        email: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      expandedRows: [],
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    this.contactsService = this.context.contactsService;
    this.toastService = this.context.toastService;

    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    if (this.state.filters.last_name) {
      filters.push(
        JSON.stringify({
          orConditions: [
            {
              field: 'last_name',
              value: this.state.filters.last_name,
              condition: 'iLike',
            },
            {
              field: 'first_name',
              value: this.state.filters.last_name,
              condition: 'iLike',
            },
          ],
        })
      );
    }

    if (this.state.filters.email) {
      filters.push(
        JSON.stringify({
          field: 'email',
          value: this.state.filters.email,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.phone_number) {
      filters.push(
        JSON.stringify({
          field: 'phone_number',
          value: this.state.filters.phone_number,
          condition: 'iLike',
        })
      );
    }

    const sortByField = this.state.sortBy?.field ?? 'last_name';

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.contactsService
      ?.getContacts(params)
      .then(data => {
        const dataRecords = (data.records as Contact[]) ?? [];

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  rowExpansionTemplate(contact: Contact) {
    return <ContactCompanyListComponent contact={contact} />;
  }

  render() {
    const nameBodyTemplate = (contact: Contact) => {
      return <NavLink to={'contact/' + contact.id}>{`${contact.first_name} ${contact.last_name}`}</NavLink>;
    };

    const nameFilter = (
      <InputText
        name="last_name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const emailFilter = (
      <InputText
        name="email"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const phoneNumberFilter = (
      <InputText
        name="phone_number"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    return (
      <div id="contacts_page_container" className="page-container">
        <TwoDataTable
          id={'contacts-table'}
          sizeIdentifiers={[]}
          selectedItems={[]}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'contacts_page_container'}
          rowExpansionTemplate={data => this.rowExpansionTemplate(data)}
          expandedRows={this.state.expandedRows}
          onRowToggle={e => {
            this.setState({expandedRows: e.data});
          }}
        >
          <Column expander className={'table-expander'} bodyClassName={'table-expander'} />
          <Column
            header="Name"
            field="last_name"
            filter
            filterElement={nameFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
            body={nameBodyTemplate}
          />
          <Column
            header="Email"
            field="email"
            filter
            filterElement={emailFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
          <Column
            header="Phone Number"
            field="phone_number"
            filter
            filterElement={phoneNumberFilter}
            style={{width: '180px'}}
            sortable
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default withRouter(ContactListComponent);
