import {MapOf, PriceListVersion, User} from 'two-core';
import React from 'react';
import {DateTime} from 'luxon';
import formats from '../../config/formats';

interface Props {
  priceListVersion: PriceListVersion;
  userMap: MapOf<User>;
}

class PriceListVersionDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  getFormattedDate(date: Date) {
    return DateTime.fromISO(date.toString()).toFormat(formats.date);
  }

  getUserFullName(userId: string) {
    return this.props.userMap[userId].full_name;
  }

  render() {
    const {priceListVersion} = this.props;
    return (
      <div className={'p-d-flex p-flex-column'}>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className="p-col-12 p-md-9">
            <span>{priceListVersion.stage ?? ''}</span>
          </div>
        </div>
        <div className="p-d-flex p-ai-center">
          <label htmlFor="based" className="p-col-12 p-md-3">
            based on definition
          </label>
          <div className="p-col-12 p-md-9">
            <span>{priceListVersion.base_definition_revision_id ?? ''}</span>
          </div>
        </div>
        {priceListVersion.created_at && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="created_at" className="p-col-12 p-md-3">
              created
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getFormattedDate(priceListVersion.created_at)}</span>
            </div>
          </div>
        )}
        {priceListVersion.created_by && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="created_by" className="p-col-12 p-md-3">
              created by
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getUserFullName(priceListVersion.created_by)}</span>
            </div>
          </div>
        )}
        {priceListVersion.released_at && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="released_at" className="p-col-12 p-md-3">
              released
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getFormattedDate(priceListVersion.released_at)}</span>
            </div>
          </div>
        )}
        {priceListVersion.released_by && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="released_by" className="p-col-12 p-md-3">
              released by
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getUserFullName(priceListVersion.released_by)}</span>
            </div>
          </div>
        )}
        {priceListVersion.deprecated_at && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="deprecated_at" className="p-col-12 p-md-3">
              deprecated
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getFormattedDate(priceListVersion.deprecated_at)}</span>
            </div>
          </div>
        )}
        {priceListVersion.deprecated_by && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="deprecated_by" className="p-col-12 p-md-3">
              deprecated by
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getUserFullName(priceListVersion.deprecated_by)}</span>
            </div>
          </div>
        )}
        {priceListVersion.cancelled_at && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="deprecated_at" className="p-col-12 p-md-3">
              cancelled
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getFormattedDate(priceListVersion.cancelled_at)}</span>
            </div>
          </div>
        )}
        {priceListVersion.cancelled_by && (
          <div className="p-d-flex p-ai-center">
            <label htmlFor="cancelled_by" className="p-col-12 p-md-3">
              cancelled by
            </label>
            <div className="p-col-12 p-md-9">
              <span>{this.getUserFullName(priceListVersion.cancelled_by)}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PriceListVersionDetail;
