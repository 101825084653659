import {PriceListPickListValue, PriceListProductPriceItem} from 'two-core';
import React from 'react';
import {InputText} from 'primereact/inputtext';
import {DropdownChangeParams} from 'primereact/dropdown';
import {Button} from 'primereact/button';

interface Props {
  items: PriceListProductPriceItem[];
  productCostingIndex: number;
  handleValueChange: (
    value: number | PriceListPickListValue[],
    productCostingIndex: number,
    surchargeIndex: number
  ) => void;
  handleTitleChange: (value: string, productCostingIndex: number, surchargeIndex: number) => void;
}

interface State {
  items: PriceListProductPriceItem[];
}

class EditPriceListProductPriceItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
    };

    this.loadData = this.loadData.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.isAnyItemEmpty = this.isAnyItemEmpty.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      items: this.props.items,
    });
  }

  handleValueChange(
    e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams,
    itemIndex: number,
    valueIndex?: number,
    name?: string
  ) {
    const {items} = this.state;
    if (Array.isArray(items[itemIndex].value) && valueIndex !== undefined) {
      const values = items[itemIndex].value as unknown as PriceListPickListValue[];

      name === 'key' ? (values[valueIndex].key = e.target.value) : (values[valueIndex].value = e.target.value);

      items[itemIndex].value = values;
    } else {
      items[itemIndex].value = e.target.value;
    }

    this.props.handleValueChange(items[itemIndex].value, this.props.productCostingIndex, itemIndex);
  }

  handleRemoveItem(itemIndex: number, valueIndex?: number) {
    const {items} = this.state;
    if (Array.isArray(items[itemIndex].value) && valueIndex !== undefined) {
      const values = items[itemIndex].value as unknown as PriceListPickListValue[];

      values.splice(valueIndex, 1);

      items[itemIndex].value = values;
    }

    this.props.handleValueChange(items[itemIndex].value, this.props.productCostingIndex, itemIndex);
  }

  handleAddItem(itemIndex: number) {
    const {items} = this.state;
    if (Array.isArray(items[itemIndex].value)) {
      const values = items[itemIndex].value as unknown as PriceListPickListValue[];

      values.push({
        key: '',
        value: 0,
      });

      items[itemIndex].value = values;
    }

    this.props.handleValueChange(items[itemIndex].value, this.props.productCostingIndex, itemIndex);
  }

  handleTitleChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams, itemIndex: number) {
    this.props.handleTitleChange(e.target.value, this.props.productCostingIndex, itemIndex);
  }

  isAnyItemEmpty(itemIndex: number) {
    let result = false;
    const {items} = this.state;
    if (items.length) {
      if (Array.isArray(items[itemIndex].value)) {
        const values = items[itemIndex].value as unknown as PriceListPickListValue[];
        values.forEach(value => {
          if (value.key === '' || value.key === undefined) {
            result = true;
          }
        });
      }
    } else {
      result = true;
    }

    return result;
  }

  render() {
    return (
      <>
        {this.props.items.map((item: PriceListProductPriceItem, itemIndex: number) => {
          return (
            <div key={item.sku}>
              <div className="p-d-flex p-ai-center p-mt-4">
                <label htmlFor="stage" className="p-col-12 p-md-3">
                  title ({item.sku})
                </label>
                <div className="p-col-12 p-md-9">
                  <span className="p-float-label">
                    <InputText
                      name="title"
                      value={item.title ?? ''}
                      onChange={e => this.handleTitleChange(e, itemIndex)}
                    />
                    <label htmlFor="title">Title</label>
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-ai-center p-mt-2">
                <label htmlFor="stage" className="p-col-12 p-md-3">
                  items ({item.sku})
                </label>
                <div className="p-col-12 p-md-9">
                  {item.value_type === 'picklist' ? (
                    <div>
                      {Array.isArray(item.value) &&
                        item.value.map((value, index) => {
                          return (
                            <div key={index}>
                              <div className={'p-fluid p-grid p-mt-2'}>
                                <div className={'p-col-4'}>
                                  <span className="p-float-label">
                                    <InputText
                                      name="key"
                                      value={value.key ?? ''}
                                      onChange={e => this.handleValueChange(e, itemIndex, index, 'key')}
                                    />
                                    <label htmlFor="key">key</label>
                                  </span>
                                </div>
                                <div className={'p-col-4'}>
                                  <span className="p-float-label">
                                    <InputText
                                      name="value"
                                      value={value.value ?? ''}
                                      onChange={e => this.handleValueChange(e, itemIndex, index)}
                                    />
                                    <label htmlFor="value">value</label>
                                  </span>
                                </div>
                                {Array.isArray(item.value) &&
                                  item.value.length > 1 &&
                                  index + 1 === item.value.length && (
                                    <div className={'p-col-1'}>
                                      <Button
                                        label="X"
                                        className={'p-mr-2'}
                                        onClick={() => {
                                          this.handleRemoveItem(itemIndex, index);
                                        }}
                                        title={'Remove this item'}
                                      />
                                    </div>
                                  )}
                              </div>
                              {!this.isAnyItemEmpty(itemIndex) &&
                                Array.isArray(item.value) &&
                                index + 1 === item.value.length && (
                                  <div className={'p-fluid p-grid p-mt-2'}>
                                    <div className={'p-col-2'}>
                                      <Button
                                        label="add"
                                        className={'p-mr-2'}
                                        onClick={() => {
                                          this.handleAddItem(itemIndex);
                                        }}
                                        title={'Remove this item'}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <span className="p-float-label">
                      <InputText
                        name="value"
                        value={(item.value as number) ?? ''}
                        onChange={e => this.handleValueChange(e, itemIndex)}
                      />
                      <label htmlFor="value">Value</label>
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default EditPriceListProductPriceItemComponent;
