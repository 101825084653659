import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {messages} from '../../config/messages';
import {DropdownOption, PriceDefinitionRevision, PriceList, PriceListVersion, QueryParameter} from 'two-core';
import PriceListDefinitionRevisionsService from '../../services/PriceListDefinitionRevisionsService';
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import PriceListsService from '../../services/PriceListsService';
import PriceListVersionService from '../../services/PriceListVersionService';
import {RouteComponentProps, withRouter} from 'react-router-dom';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  selectedPriceList: PriceList;
}

interface State {
  loading: boolean;
  priceDefinitionRevisions: PriceDefinitionRevision[];
  copySwitch: boolean;
  version: PriceListVersion;
  priceLists: PriceList[];
  copyPriceList: PriceList;
  baseDefinitionRevisionID: number | undefined;
}

class AddPriceListVersionDialog extends React.Component<RouteComponentProps<{}> & Props, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  priceListDefinitionRevisionsService: PriceListDefinitionRevisionsService | null = null;
  priceListsService: PriceListsService | null = null;
  priceListVersionService: PriceListVersionService | null = null;

  constructor(props: RouteComponentProps<{}> & Props) {
    super(props);
    this.state = {
      loading: false,
      priceDefinitionRevisions: [],
      copySwitch: false,
      version: {
        index: 1,
        price_list_id: this.props.selectedPriceList.id ?? 0,
        base_definition_revision_id: 0,
        stage: 'Draft',
        created_at: new Date(),
        created_by: '',
      },
      priceLists: [],
      copyPriceList: {
        name: '',
        stage: 'Draft',
      },
      baseDefinitionRevisionID: undefined,
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.createVersion = this.createVersion.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadPriceLists = this.loadPriceLists.bind(this);
    this.getAllActivePriceListsOptions = this.getAllActivePriceListsOptions.bind(this);
    this.getDefinitionsRevisionsOptions = this.getDefinitionsRevisionsOptions.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.priceListsService = this.context.priceListsService;
    this.priceListVersionService = this.context.priceListVersionsService;
    this.priceListDefinitionRevisionsService = this.context.priceListDefinitionRevisionsService;

    this.loadPriceLists();
  }

  loadPriceLists() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'deleted',
        value: false,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const dataRecords = (data.records as PriceList[]) ?? [];

        this.setState({
          priceLists: dataRecords,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadData() {
    this.setState({loading: true, copyPriceList: this.props.selectedPriceList});

    this.priceListDefinitionRevisionsService
      ?.getPriceListDefinitionRevisions({
        orderBys: [
          JSON.stringify({
            field: 'id',
            direction: 'DESC',
          }),
        ],
        aggregate: false,
      })
      .then(data => {
        const definitions = data.records as PriceDefinitionRevision[];
        this.setState({
          priceDefinitionRevisions: definitions,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'Sorry, price definition revisions load failed, please try again.'
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  hideDialog() {
    this.setState({
      version: {
        index: 0,
        stage: 'Draft',
        base_definition_revision_id: 0,
        created_by: '',
        created_at: new Date(),
        price_list_id: 0,
      },
      loading: false,
    });
    this.props.onHide();
    MessageService.sendMessage(messages.priceListUpdated);
  }

  getDefinitionsRevisionsOptions() {
    const options: DropdownOption[] = [];
    this.state.priceDefinitionRevisions.forEach(definition => {
      options.push({
        label: definition.id + ' (' + definition.stage + ')',
        value: definition.id as number,
      });
    });

    return options;
  }

  getAllActivePriceListsOptions() {
    const options: DropdownOption[] = [];
    this.state.priceLists.forEach(priceList => {
      if (priceList.deleted === false) {
        options.push({
          label: priceList.id === this.props.selectedPriceList.id ? 'This price list' : priceList.name,
          value: priceList.id as number,
        });
      }
    });

    return options;
  }

  getVersionsOptions(priceList: PriceList) {
    const options: DropdownOption[] = [];
    if (priceList.price_list_versions) {
      priceList.price_list_versions.forEach(version => {
        if (version !== null) {
          options.push({
            label: version.index + ' (' + version.stage + ')',
            value: version.id as number,
          });
        }
      });
    }

    return options;
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  async save() {
    this.setState({
      loading: true,
    });

    const version: PriceListVersion = {
      created_by: this.getCurrentUserId(),
      created_at: new Date(),
      base_definition_revision_id: this.state.baseDefinitionRevisionID ?? 0,
      stage: 'Draft',
      index: 1,
      price_list_id: this.props.selectedPriceList.id ?? 0,
    };
    if (this.props.selectedPriceList.price_list_versions) {
      const sortedVersionsByIndex: PriceListVersion[] = this.props.selectedPriceList.price_list_versions.sort(
        (a: PriceListVersion, b: PriceListVersion) => b.index - a.index
      );
      version.index = sortedVersionsByIndex[0].index + 1;
    }
    await this.createVersion(version);
    this.props.onHide();
    MessageService.sendMessage(messages.priceListVersionCreated);
  }

  async createVersion(version: PriceListVersion) {
    let copyVersionID = undefined;
    if (this.state.copySwitch) {
      copyVersionID = await this.state.version.id;
    }
    return this.priceListVersionService
      ?.createPriceListVersion(version, copyVersionID)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Price list version created successfully.');
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Price list version create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  renderFooter() {
    return !this.state.loading ? (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={() => this.props.onHide()} />
        <Button
          label="Create"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    const {loading, copySwitch, copyPriceList, version, baseDefinitionRevisionID} = this.state;
    return (
      <Dialog
        header={'Creat New Draft Version for ' + this.props.selectedPriceList.name}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '80%'}}
        modal
        onHide={this.hideDialog}
        onShow={this.loadData}
      >
        {!loading ? (
          <>
            <div className="p-grid">
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="definition" className="p-col-4">
                  Base the new version on price definition revision
                </label>
                <div className="p-col-4">
                  <span className="p-fluid">
                    <Dropdown
                      options={this.getDefinitionsRevisionsOptions()}
                      value={baseDefinitionRevisionID ?? ''}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState({baseDefinitionRevisionID: value});
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="copy" className="p-col-2">
                  And copy prices
                </label>
                <div className="p-col-1">
                  <span className="p-fluid">
                    <InputSwitch
                      name="showItemsSwitch"
                      className={'p-as-center'}
                      checked={copySwitch}
                      onChange={() => {
                        this.setState({copySwitch: !copySwitch});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="price-list" className="p-col-1">
                  from
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      options={copySwitch ? this.getAllActivePriceListsOptions() : []}
                      disabled={!copySwitch}
                      value={copyPriceList.id ?? this.props.selectedPriceList.id}
                      onChange={e => {
                        const pL = this.state.priceLists.find(list => list.id === e.target.value);
                        this.setState({
                          copyPriceList: pL ? pL : this.state.copyPriceList,
                        });
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="version" className="p-col-1">
                  and version
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      options={copyPriceList.id && copyPriceList.id > 0 ? this.getVersionsOptions(copyPriceList) : []}
                      value={version.id ?? ''}
                      disabled={!copySwitch}
                      onChange={e => {
                        const newVersion = copyPriceList.price_list_versions
                          ? copyPriceList.price_list_versions.find(list => list.id === e.target.value)
                          : version;
                        this.setState({
                          version: newVersion ? newVersion : version,
                        });
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default withRouter(AddPriceListVersionDialog);
