import React from 'react';
import {MapOf, PriceList, PriceListVersion, QueryParameter, User} from 'two-core';
import {DataTableSortOrderType} from 'primereact/datatable';
import {AppColumnMenuBodyTemplate, AppContext, ToastService, TwoDataTable, UsersService} from 'two-app-ui';
import PriceListVersionService from '../../services/PriceListVersionService';
import {Toast} from 'primereact/toast';
import {Column} from 'primereact/column';
import {DateTime} from 'luxon';
import {formats} from '../../config/formats';
import {NavLink} from 'react-router-dom';

interface Props {
  priceList: PriceList;
  usersMap: MapOf<User>;
}

interface State {
  loading: boolean;
  items: PriceListVersion[];
  selectedItems: PriceListVersion[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  filters: {};
}

class PriceListVersions extends React.Component<Props, State> {
  static contextType = AppContext;

  priceListVersionsService: PriceListVersionService | null = null;
  toastService: ToastService | null = null;
  toast: React.RefObject<Toast>;
  usersService: UsersService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      selectedItems: [],
      totalItems: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      filters: {},
    };
    this.toast = React.createRef();
    this.loadData = this.loadData.bind(this);
    this.getUserFullName = this.getUserFullName.bind(this);
    this.getDateAndUserName = this.getDateAndUserName.bind(this);
  }

  componentDidMount() {
    this.priceListVersionsService = this.context.priceListVersionsService;
    this.toastService = this.context.toastService;
    this.usersService = this.context.usersService;

    this.loadData();
  }

  loadData() {
    this.setState({loading: true});

    const priceList = this.props.priceList;
    const filters: string[] = [];
    const sortBy: string[] = [];

    if (!priceList) {
      this.toastService?.showError(this.toast, 'Sorry, price list versions load failed, please try again.');

      this.setState({
        loading: false,
      });
    } else {
      filters.push(
        JSON.stringify({
          field: 'price_list_id',
          value: priceList.id,
        })
      );
      sortBy.push(
        JSON.stringify({
          field: 'id',
          direction: 'DESC',
        })
      );

      const params: QueryParameter = {
        filters: filters,
        orderBys: sortBy,
        aggregate: true,
      };

      this.priceListVersionsService
        ?.getPriceListVersions(params)
        .then(data => {
          const dataRecords = data?.records as PriceListVersion[];
          this.setState({
            loading: false,
            items: dataRecords,
            totalItems: data?.total_records ?? 0,
          });
        })
        .catch(e => {
          this.setState({loading: false});
          this.toastService?.showError(this.toast, 'Sorry, price list versions load failed, please try again.');
          console.error(e);
        });
    }
  }

  getUserFullName(userId: string) {
    return this.props.usersMap[userId].full_name;
  }

  getDateAndUserName(date: Date, userId: string) {
    let dateFormated = '';
    let userName: string | undefined = '';
    if (date) {
      dateFormated = DateTime.fromISO(date.toString()).toFormat(formats.date);
    }
    if (userId) {
      userName = this.getUserFullName(userId);
    }

    let result: string | undefined = dateFormated !== '' ? dateFormated : '';
    if (userName !== '') {
      result !== '' ? (result = result + ' ' + userName) : (result = userName);
    }
    return result === undefined ? '' : result;
  }

  dateUserNameTemplate(field: string, priceListVersion: PriceListVersion) {
    const at = (field + '_at') as keyof PriceListVersion;
    const by = (field + '_by') as keyof PriceListVersion;

    return this.getDateAndUserName(priceListVersion[at] as Date, priceListVersion[by] as string);
  }

  indexTemplate(version: PriceListVersion) {
    return (
      <AppColumnMenuBodyTemplate
        key={version.id}
        rowItemIdentifier={version?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        selectedItems={[]}
      >
        <NavLink to={'/price-list-version/' + version.id}>{`${version.index}` ?? ''}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  render() {
    return (
      <div id="price-list-versions-page" className="page-container">
        <TwoDataTable
          id={'price-list-versions-table'}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={{}}
          selectedItems={[]}
        >
          <Column header="Version" field="index" body={this.indexTemplate} />
          <Column header="Stage" field="stage" style={{width: '180px'}} />
          <Column header="Based on" field="base_definition_revision_id" style={{width: '180px'}} />
          <Column
            header="Created"
            field="created"
            body={priceListVersion => this.dateUserNameTemplate('created', priceListVersion)}
            style={{width: '180px'}}
          />
          <Column
            header="Released"
            field="released"
            body={priceListVersion => this.dateUserNameTemplate('released', priceListVersion)}
            style={{width: '180px'}}
          />
          <Column
            header="Deprecated"
            field="deprecated"
            body={priceListVersion => this.dateUserNameTemplate('deprecated', priceListVersion)}
            style={{width: '180px'}}
          />
          <Column
            header="Cancelled"
            field="cancelled"
            body={priceListVersion => this.dateUserNameTemplate('cancelled', priceListVersion)}
            style={{width: '180px'}}
          />
        </TwoDataTable>
      </div>
    );
  }
}

export default PriceListVersions;
