import React from 'react';

import OrderListComponent from '../Orders/OrderListComponent';

class DeliveredOrdersComponent extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent mode={'Delivered'} />;
  }
}

export default DeliveredOrdersComponent;
