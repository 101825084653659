import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MenuItemOptions} from 'primereact/menuitem';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {AppContext, AppMenuItem, AppMenuItemTemplate, MessageService, ToastService, TwoDataTable} from 'two-app-ui';
import {Company, Location, QueryParameter} from 'two-core';
import {messages} from '../../config/messages';
import {values} from '../../config/values';
import LocationsService from '../../services/LocationsService';
import AddEditLocationDialog from '../Location/AddEditLocationDialog';

export const types: string[] = ['factory', 'warehouse', 'dealership', 'end-customer', 'service', 'other'];

interface Props {
  company: Company;
}

interface State {
  loading: boolean;
  items: Location[];
  selectedItem: Location | undefined;
  total_items: number;
  activeFilters: {};
  filters: {
    name: string;
    type: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAddEditDialog: boolean;
}

class CompanyLocations extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService: LocationsService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;
  subscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      selectedItem: undefined,
      total_items: 0,
      activeFilters: {},
      filters: {
        name: '',
        type: '',
      },
      pagination: {
        pageSize: 10,
        offset: 0,
      },

      sortBy: null,
      showAddEditDialog: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.hideAddEditDialog = this.hideAddEditDialog.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.toastService = this.context.toastService;

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.locationUpdate) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'dealership_id',
        value: this.props.company.id ?? '',
      })
    );

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'like',
        })
      );
    }

    if (this.state.filters.type && this.state.filters.type.length) {
      filters.push(
        JSON.stringify({
          field: 'type',
          value: this.state.filters.type,
          condition: 'in',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'name',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
      showAll: true,
    };

    this.locationsService
      ?.getLocations(params)
      .then(data => {
        const locations = (data.records as Location[]) ?? [];
        this.setState({
          items: locations,
          total_items: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  initTopMenuItems(): AppMenuItem[] {
    const addNewMenuItem: AppMenuItem = {
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddEditDialog();
      },
    };
    return [addNewMenuItem];
  }

  showAddEditDialog(selectedLocation?: Location) {
    this.setState({showAddEditDialog: true, selectedItem: selectedLocation});
  }

  hideAddEditDialog() {
    this.setState({showAddEditDialog: false, selectedItem: undefined});
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  selectLocation(loc: Location) {
    this.setState({selectedItem: loc});
  }

  nameBodyTemplate(rowData: Location) {
    return (
      <a href={'#/'} onClick={() => this.showAddEditDialog(rowData)}>
        {rowData.name}
      </a>
    );
  }

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const typeFilter = (
      <MultiSelect
        value={this.state.filters.type}
        options={types}
        name="type"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const adressBodyTemplate = (rowData: Location) => {
      return (
        <>
          {`${rowData.address.street}, ${rowData.address.suburb}, ${rowData.address.state}, ${rowData.address.country}`}
        </>
      );
    };

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="location_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'location_list_page'}
            heightToScroll={undefined}
            selectedItems={[]}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={this.state.activeFilters}
            value={this.state.items}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            initMenuItems={() => this.initTopMenuItems()}
            addNewItemEvent={() => this.showAddEditDialog()}
          >
            <Column
              header="Name"
              field="name"
              filter
              filterElement={nameFilter}
              body={this.nameBodyTemplate}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Type"
              field="type"
              filter
              filterElement={typeFilter}
              sortable
              style={{width: '170px'}}
              showFilterMenu={false}
            />
            <Column
              header="Address"
              field="address"
              body={adressBodyTemplate}
              sortable
              className={'p-col-10'}
              showFilterMenu={false}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
        </div>
        <AddEditLocationDialog
          toast={this.toast}
          locationId={this.state.selectedItem?.id}
          showDialog={this.state.showAddEditDialog}
          onHide={this.hideAddEditDialog}
          companyId={this.props.company.id ?? ''}
        />
      </div>
    );
  }
}

export default CompanyLocations;
