import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, PriceDefinitionRevision} from 'two-core';

class PriceListDefinitionRevisionsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.priceListDefinitionRevisions ?? '';
  }

  async getPriceListDefinitionRevisions(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updatePriceListDefinitionRevision(id: string, data: PriceDefinitionRevision): Promise<PriceDefinitionRevision> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as PriceDefinitionRevision);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createPriceListDefinitionRevision(data: PriceDefinitionRevision): Promise<PriceDefinitionRevision> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as PriceDefinitionRevision);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default PriceListDefinitionRevisionsService;
