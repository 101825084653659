import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Company, User, State, CompanyPatch} from 'two-core';
import {InputText} from 'primereact/inputtext';
import {companyStates, companyStages, businessEntityTypes, fittingTypes} from '../../config/values';
import {InputSwitch} from 'primereact/inputswitch';
import {Divider} from 'primereact/divider';
import {MultiSelect} from 'primereact/multiselect';

interface CompanyEditProps {
  company?: Company;
  companyPatch: CompanyPatch;
  users: User[];
  states: State[];
  companies: Company[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
  handleBillingAddressChange: (e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) => void;
  handleFitForChange: (e: DropdownChangeParams) => void;
  selectedFitForIds: string[];
}

const CompanyEditComponent = ({
  company,
  companyPatch,
  handleInputChange,
  users,
  companies,
  handleBillingAddressChange,
  handleFitForChange,
  selectedFitForIds,
}: CompanyEditProps) => {
  const companyFittingTypes = companyPatch.fitting_types ?? company?.fitting_types;
  const currentFittingTypes = companyFittingTypes ? companyFittingTypes?.split(',') : [];
  const isDistributor = companyPatch.is_distributor ?? company?.is_distributor ?? false;
  return (
    <>
      <div id="customer-detail-item" className="w-100">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            <div className="p-grid p-ai-center">
              <label htmlFor="name" className="p-col-12 p-lg-4">
                entity name
              </label>
              <div className="p-col-12 p-lg-8">
                <span className="p-fluid">
                  <InputText
                    name="name"
                    value={companyPatch.name ?? company?.name ?? ''}
                    onChange={e => handleInputChange(e)}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-lg-6">
            <div className="p-grid p-ai-center">
              <label htmlFor="trading_as" className="p-col-12 p-lg-4">
                trading as
              </label>
              <div className="p-col-12 p-lg-8">
                <span className="p-fluid">
                  <InputText
                    name="trading_as"
                    value={companyPatch.trading_as ?? company?.trading_as ?? ''}
                    onChange={e => handleInputChange(e)}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="business_entity" className="p-col-12 p-lg-4">
                  entity type
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <Dropdown
                      name="business_entity"
                      value={companyPatch.business_entity ?? company?.business_entity ?? ''}
                      options={businessEntityTypes}
                      onChange={e => handleInputChange(e)}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="account_number" className="p-col-12 p-lg-4">
                  account number
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="account_number"
                      value={companyPatch.account_number ?? company?.account_number ?? ''}
                      onChange={e => handleInputChange(e)}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="tax_id" className="p-col-12 p-lg-4">
                  tax id
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.tax_id ?? company?.tax_id ?? ''}
                      name="tax_id"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="phone_number" className="p-col-12 p-lg-4">
                  phone number
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.phone_number ?? company?.phone_number ?? ''}
                      name="phone_number"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid p-ai-center">
                <label htmlFor="accounts_emails" className="p-col-12 p-lg-2">
                  accounts emails
                </label>
                <div className="p-col-12 p-lg-10">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.accounts_emails ?? company?.accounts_emails}
                      name="accounts_emails"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid p-ai-center">
                <label htmlFor="accounts_emails" className="p-col-12 p-lg-2">
                  delivery emails
                </label>
                <div className="p-col-12 p-lg-10">
                  <span className="p-fluid">
                    <InputText
                      value={companyPatch.delivery_emails ?? company?.delivery_emails}
                      name="delivery_emails"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="street" className="p-col-12 p-lg-4">
                  billing address
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="street"
                      value={companyPatch.billing_address?.street ?? company?.billing_address?.street ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="suburb" className="p-col-12 p-lg-4">
                  suburb/city
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="suburb"
                      value={companyPatch.billing_address?.suburb ?? company?.billing_address?.suburb ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="state" className="p-col-12 p-lg-4">
                  state
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="state"
                      value={companyPatch.billing_address?.state ?? company?.billing_address?.state ?? ''}
                      onChange={e => handleBillingAddressChange(e)}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="country" className="p-col-12 p-lg-4">
                  country
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="country"
                      value={companyPatch.billing_address?.country ?? company?.billing_address?.country ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="postCode" className="p-col-12 p-lg-4">
                  postcode
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <InputText
                      name="postCode"
                      value={companyPatch.billing_address?.postCode ?? company?.billing_address?.postCode ?? ''}
                      onChange={e => {
                        handleBillingAddressChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="state" className="p-col-12 p-lg-4">
                  state
                </label>
                <div className="p-col-12 p-lg-8">
                  <Dropdown
                    name="state"
                    value={companyPatch.state ?? company?.state ?? ''}
                    options={companyStates}
                    onChange={e => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="is_distributor" className="p-col-12 p-lg-4">
                  sales rep
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <Dropdown
                      filter
                      name="sales_rep"
                      options={users}
                      showClear
                      value={companyPatch.sales_rep ?? company?.sales_rep ?? ''}
                      onChange={e => {
                        handleInputChange(e);
                      }}
                      optionValue="id"
                      optionLabel="full_name"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="is_distributor" className="p-col-12 p-lg-4">
                  stage
                </label>
                <div className="p-col-12 p-lg-8">
                  <span className="p-fluid">
                    <Dropdown
                      filter
                      name="stage"
                      options={companyStages}
                      showClear
                      value={companyPatch.stage ?? company?.stage ?? ''}
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="external_id" className="p-col-12 p-lg-4">
                  external id
                </label>
                <div className="p-col-12 p-lg-8">
                  <InputText
                    value={companyPatch.external_id ?? company?.external_id ?? ''}
                    name="external_id"
                    onChange={e => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="customer-detail-item">
          <div className="p-grid">
            <div className="p-col-12 p-lg-6">
              <div className="p-grid p-ai-center">
                <label htmlFor="is_distributor" className="p-col-12 p-lg-4">
                  is distributor
                </label>
                <div className="p-col-12 p-lg-8">
                  <InputSwitch
                    name="is_distributor"
                    checked={isDistributor}
                    onChange={e => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {isDistributor ? (
              <div className="p-col-12 p-lg-6">
                <div className="p-grid p-ai-center">
                  <label htmlFor="tax_id" className="p-col-12 p-lg-4">
                    distributor key
                  </label>
                  <div className="p-col-12 p-lg-8">
                    <InputText
                      value={companyPatch.distributor_key ?? company?.distributor_key ?? ''}
                      name="distributor_key"
                      onChange={e => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="p-col-12 p-lg-6">
                <div className="p-grid p-ai-center">
                  <label htmlFor="parent" className="p-col-12 p-lg-4">
                    parent
                  </label>
                  <div className="p-col-12 p-lg-8">
                    <span className="p-fluid">
                      <Dropdown
                        filter
                        name="parent_id"
                        options={companies}
                        showClear
                        value={companyPatch.parent_id ?? company?.parent_id ?? ''}
                        onChange={e => {
                          handleInputChange(e);
                        }}
                        optionValue="id"
                        optionLabel="name"
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="p-grid">
          <label htmlFor="does_quotes" className="p-col-2">
            does quotes
          </label>
          <div className="p-col-10">
            <span className="p-fluid">
              <InputSwitch
                name="does_quotes"
                checked={companyPatch.does_quotes ?? company?.does_quotes ?? false}
                onChange={handleInputChange}
              />
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label htmlFor="does_m2f" className="p-col-2">
            fitting types
          </label>
          <div className="p-col-10">
            <span className="p-fluid">
              <MultiSelect
                name="fitting_types"
                value={currentFittingTypes}
                options={fittingTypes}
                onChange={e => handleInputChange(e)}
                showClear
              />
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label htmlFor="fits_for_others" className="p-col-2">
            fits for others
          </label>
          <div className="p-col-10">
            <span className="p-fluid">
              <InputSwitch
                name="fits_for_others"
                checked={companyPatch.fits_for_others ?? company?.fits_for_others ?? false}
                onChange={handleInputChange}
              />
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label htmlFor="fits_for_others" className="p-col-2">
            fit for
          </label>
          <div className="p-col-10">
            <span className="p-fluid">
              <MultiSelect
                value={selectedFitForIds}
                options={companies}
                optionLabel="name"
                optionValue="id"
                name="fit_for"
                onChange={handleFitForChange}
                showClear
                filter={true}
              />
            </span>
          </div>
        </div>
        <div className="p-grid">
          <label htmlFor="fits_for_others" className="p-col-2">
            does orders
          </label>
          <div className="p-col-10">
            <span className="p-fluid">
              <InputSwitch
                name="does_orders"
                checked={companyPatch.does_orders ?? company?.does_orders ?? false}
                onChange={handleInputChange}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyEditComponent;
