import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Order, OrderPatch, QueryParameter} from 'two-core';

class OrdersService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.orders ?? '';
  }

  async getOrders(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiListResponse | ApiResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateOrder(id: string, data: OrderPatch): Promise<Order> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Order);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async createOrder(data: Order): Promise<Order> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Order);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default OrdersService;
