import React from 'react';
import {DataTablePageParams, DataTableSortParams, DataTableSortOrderType} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {InputText} from 'primereact/inputtext';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {MenuItemOptions} from 'primereact/menuitem';
import {Toast} from 'primereact/toast';
import {QueryParameter, Company, Order} from 'two-core';
import config from '../../config/config';
import {messages} from '../../config/messages';
import {Subscription} from 'rxjs';
import CompaniesServices from '../../services/CompaniesService';
import {NavLink} from 'react-router-dom';
import FilterboxComponent from '../FilterBox/FilterboxComponent';
import AddCompanyDialog from './AddCompanyDialog';
import {companyStages, companyStates} from '../../config/values';
import AddEditOrderDialog from '../Order/AddEditOrderDialog';
import '../../scss/CustomTable.scss';

interface State {
  loading: boolean;
  companies: Company[];
  selectedItems: Company[];
  totalItems: number;
  activeFilters: {};
  filters: {
    name: string;
    account_number: string;
    distributor_key: string;
    sales_rep: string;
    stage: string;
    state: string;
    search: string;
    price_list: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  onboarding: number;
  totalActive: number;
  totalActiveFiltered: number;
  showAddCompanyDialog: boolean;
  showAddOrderDialog: boolean;
  newOrder?: Order;
}

class CompanyListComponent extends React.Component<{}, State> {
  static contextType = AppContext;
  companiesService: CompaniesServices | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);

    this.state = {
      companies: [],
      selectedItems: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      filters: {
        name: '',
        account_number: '',
        distributor_key: '',
        sales_rep: '',
        stage: '',
        state: '',
        search: '',
        price_list: '',
      },
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      showAddCompanyDialog: false,
      onboarding: 0,
      totalActive: 0,
      totalActiveFiltered: 0,
      showAddOrderDialog: false,
    };

    this.toast = React.createRef();

    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.initMenuItems = this.initMenuItems.bind(this);
    this.salesRepBodyTemplate = this.salesRepBodyTemplate.bind(this);
    this.distributorBodyTemplate = this.distributorBodyTemplate.bind(this);
    this.showAddCompanyDialog = this.showAddCompanyDialog.bind(this);
    this.hideAddCompanyDialog = this.hideAddCompanyDialog.bind(this);
    this.showAddOrderDialog = this.showAddOrderDialog.bind(this);
    this.hideAddOrderDialog = this.hideAddOrderDialog.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.companyUpdated) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedItems;
    const selectedItemsLenght = selectedItems.length;

    const addNewMenuItem: AppMenuItem = {
      label: 'Add New',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAddCompanyDialog();
      },
    };
    menuItems.push(addNewMenuItem);

    if (selectedItemsLenght === 1) {
      // const createQuoteMenuItem: AppMenuItem = {
      //   label: 'Create Quote',
      //   faIcon: ['far', 'plus'],
      //   template: (item: AppMenuItem, options: MenuItemOptions) => {
      //     return <AppMenuItemTemplate item={item} options={options} />;
      //   },
      //   command: () => {},
      // };
      // menuItems.push(createQuoteMenuItem);

      const createOrderMenuItem: AppMenuItem = {
        label: 'Create Order',
        faIcon: ['far', 'plus'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.showAddOrderDialog();
        },
      };
      menuItems.push(createOrderMenuItem);
      menuItems.push({separator: true});

      const sendEmailMenuItem: AppMenuItem = {
        label: 'Send Email',
        faIcon: ['far', 'envelope'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {},
      };
      menuItems.push(sendEmailMenuItem);
    }

    const recordNoteMenuItem: AppMenuItem = {
      label: 'Record Note',
      faIcon: ['far', 'sticky-note'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {},
    };
    menuItems.push(recordNoteMenuItem);
    menuItems.push({separator: true});

    const deActivateMenuItem: AppMenuItem = {
      label: 'De-activate',
      faIcon: ['far', 'ban'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {},
    };
    menuItems.push(deActivateMenuItem);

    const disableAccessMenuItem: AppMenuItem = {
      label: 'Disable Access',
      faIcon: ['far', 'user-slash'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {},
    };
    menuItems.push(disableAccessMenuItem);

    const blockEmailsMenuItem: AppMenuItem = {
      label: 'Block Emails',
      faIcon: ['far', 'envelope'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {},
    };
    menuItems.push(blockEmailsMenuItem);

    return menuItems;
  }

  async showAddCompanyDialog() {
    this.setState({showAddCompanyDialog: true});
  }

  async hideAddCompanyDialog() {
    this.setState({showAddCompanyDialog: false});
  }

  async showAddOrderDialog() {
    const newOrder = new Order({
      stage: 'Estimate',
      type: 'Standard',
      priority: 1,
      reference: '',
      is_m2f: false,
      items: [],
      size: 0,
      owner: this.state.selectedItems[0].id,
      deleted: false,
    });
    this.setState({newOrder: newOrder, showAddOrderDialog: true});
  }

  async hideAddOrderDialog() {
    this.setState({newOrder: new Order({}), showAddOrderDialog: false});
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    const activeTotal = (await this.loadActiveTotal()) ?? 0;
    const onboarding = (await this.loadOnboarding()) ?? 0;

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          orConditions: [
            {
              field: 'name',
              value: this.state.filters.name,
              condition: 'iLike',
            },
            {
              field: 'trading_as',
              value: this.state.filters.name,
              condition: 'iLike',
            },
          ],
        })
      );
    }

    if (this.state.filters.account_number) {
      filters.push(
        JSON.stringify({
          field: 'account_number',
          value: this.state.filters.account_number,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.sales_rep) {
      filters.push(
        JSON.stringify({
          field: 'user.full_name',
          value: this.state.filters.sales_rep,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.state) {
      filters.push(
        JSON.stringify({
          field: 'state',
          value: this.state.filters.state,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.stage) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.distributor_key) {
      filters.push(
        JSON.stringify({
          field: 'company.distributor_key',
          value: this.state.filters.distributor_key,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.price_list) {
      filters.push(
        JSON.stringify({
          field: 'price_list',
          value: this.state.filters.price_list,
          condition: 'iLike',
        })
      );
    }

    const sortByFieldOrg = this.state.sortBy?.field;
    let sortByField = sortByFieldOrg ?? 'name';
    switch (sortByField) {
      case 'parent_company.distributor_key':
        sortByField = 'company.distributor_key';
        break;
      case 'sales_rep_user.full_name':
        sortByField = 'user.full_name';
        break;
    }

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
      search: this.state.filters.search ? this.state.filters.search : undefined,
    };

    this.setState({activeFilters: {...filters}});

    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const dataRecords = (data.records as Company[]) ?? [];

        this.handleSelectedItems(dataRecords);

        this.setState({
          companies: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
          onboarding: onboarding,
          totalActive: activeTotal,
          totalActiveFiltered: data.total_records ?? 0,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async loadActiveTotal(): Promise<number | undefined> {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['Active'],
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      offset: 0,
      page_size: 1,
      filters: filters,
    };

    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const dataRecordsCount = data?.total_records ?? 0;
        return Promise.resolve(dataRecordsCount);
      })
      .catch(e => {
        console.log(e);
        return Promise.resolve(0);
      });
  }

  async loadOnboarding(): Promise<number | undefined> {
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'stage',
        value: ['Prospective', 'Pending Application', 'Awaiting Approval'],
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      offset: 0,
      page_size: 1,
      filters: filters,
    };

    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const dataRecordsCount = data?.total_records ?? 0;
        return Promise.resolve(dataRecordsCount);
      })
      .catch(e => {
        console.log(e);
        return Promise.resolve(0);
      });
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleSelectedItems(allItems: Company[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Company[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Company[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Company) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  nameBodyTemplate(rowData: Company) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        <NavLink to={'company/' + rowData.id}>{`${rowData.name} ${rowData.trading_as ?? ''}`}</NavLink>
      </AppColumnMenuBodyTemplate>
    );
  }

  stageBodyTemplate(rowData: Company) {
    return (
      <span
        className={`stage-badge stage-company-${rowData
          .stage!.toLowerCase()
          .replaceAll(' ', '-')
          .replaceAll(/[()]/g, '')}`}
      >
        {rowData.stage}
      </span>
    );
  }

  salesRepBodyTemplate(rowData: Company) {
    const user = rowData.sales_rep_user;
    return <span>{user?.full_name ?? ''}</span>;
  }

  distributorBodyTemplate(rowData: Company) {
    const parentCompany = rowData.parent_company;
    return <span>{parentCompany?.distributor_key ?? ''}</span>;
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, config().system.stop_typing_detection);
  };

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const acountNumberFilter = (
      <InputText
        name="account_number"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const salesRepFilter = (
      <InputText
        name="sales_rep"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const distributorFilter = (
      <InputText
        name="distributor_key"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );
    const priceListFilter = (
      <InputText
        name="price_list"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const itemTemplate = (option: string) => {
      return (
        <span
          className={`stage-badge stage-company-${option.toLowerCase().replaceAll(' ', '-').replaceAll(/[()]/g, '')}`}
        >
          {option}
        </span>
      );
    };

    const stageFilter = (
      <MultiSelect
        value={this.state.filters.stage}
        options={companyStages}
        name="stage"
        className="form-filter stage-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
        itemTemplate={itemTemplate}
      />
    );

    const stateFilter = (
      <MultiSelect
        value={this.state.filters.state}
        options={companyStates}
        name="state"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <div id="companies_page_container" className="page-container">
        <div id="filter-box" className={'p-d-flex'}>
          <FilterboxComponent
            onboarding={this.state.onboarding}
            totalActive={this.state.totalActive}
            totalActiveFiltered={this.state.totalActiveFiltered}
            onFilterChange={e => this.onFilterChange(e)}
            handleFilterChange={e => this.handleFilterChange(e)}
          />
        </div>
        <TwoDataTable
          id={'companies-table'}
          sizeIdentifiers={['filter-box']}
          selectedItems={this.state.selectedItems}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onPage={e => this.onPageChange(e as DataTablePageParams)}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={this.state.activeFilters}
          value={this.state.companies}
          totalRecords={this.state.totalItems}
          pageSizeIdentifier={'companies_page_container'}
          initMenuItems={() => this.initMenuItems()}
          addNewItemEvent={() => this.showAddCompanyDialog()}
          selectionMode="multiple"
          handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Company[])}
        >
          <Column
            header="Company Name"
            field="name"
            body={item => this.nameBodyTemplate(item)}
            filter
            filterElement={nameFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-4'}
          />
          <Column
            header="Acc Number"
            field="account_number"
            filter
            filterElement={acountNumberFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-1'}
          />
          <Column
            header="State"
            field="state"
            filter
            filterElement={stateFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-1'}
          />
          <Column
            header="Stage"
            field="stage"
            body={this.stageBodyTemplate}
            filter
            filterElement={stageFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-1'}
          />
          <Column
            header="Sales Rep"
            field="sales_rep_user.full_name"
            body={item => this.salesRepBodyTemplate(item)}
            filter
            filterElement={salesRepFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-2'}
          />
          <Column
            header="Distributor"
            field="parent_company.distributor_key"
            body={item => this.distributorBodyTemplate(item)}
            filter
            filterElement={distributorFilter}
            sortable
            showFilterMenu={false}
            className={'p-col-2'}
          />

          <Column
            header="Price Lists"
            field="price_list"
            className={'p-col-1'}
            filter
            filterElement={priceListFilter}
            showFilterMenu={false}
          />
        </TwoDataTable>
        <Toast ref={this.toast} />
        <AddCompanyDialog
          showDialog={this.state.showAddCompanyDialog}
          onHide={() => this.hideAddCompanyDialog()}
          toast={this.toast}
        />
        <AddEditOrderDialog
          toast={this.toast}
          showDialog={this.state.showAddOrderDialog}
          onHide={this.hideAddOrderDialog}
          newOrder={this.state.newOrder}
        />
      </div>
    );
  }
}

export default CompanyListComponent;
