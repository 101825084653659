import React from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {AppContext} from 'two-app-ui';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Address, LocationReference, Order} from 'two-core';
import {formats} from '../../config/formats';

interface Props {
  order: Order;
}

class OrderDetail extends React.Component<Props, {}> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.priorityTemplate = this.priorityTemplate.bind(this);
  }

  priorityTemplate(priority: number) {
    let priorityText = '';
    switch (priority) {
      case 1:
        priorityText = '!';
        break;
      case 2:
        priorityText = '!!';
        break;
      case 3:
        priorityText = '!!!';
        break;
    }
    return <span>{priorityText}</span>;
  }

  render() {
    const order = this.props.order;
    let shippingAddress = undefined;
    if ((order.shipping_address as Address).street) {
      const address = order.shipping_address as Address;
      shippingAddress = address?.street + ' ' + address?.state;
    } else {
      shippingAddress = (order.shipping_address as LocationReference).address;
    }

    return (
      <Card className={'p-d-flex order-detail-item'}>
        {/** Reference */}
        <div className="p-d-flex">
          <label htmlFor="reference" className="p-col-12 p-md-3">
            reference
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.reference ?? ''}</span>
          </div>
        </div>

        {/** Stage */}
        <div className="p-d-flex">
          <label htmlFor="production_stage" className="p-col-12 p-md-3">
            stage
          </label>
          <div className={'p-col-12 p-md-9'}>
            <span>{order.stage}</span>
          </div>
        </div>

        {/** Type */}
        <div className="p-d-flex">
          <label htmlFor="type" className="p-col-12 p-md-3">
            type
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.type}</span>
          </div>
        </div>

        {/** Priority */}
        <div className="p-d-flex">
          <label htmlFor="priority" className="p-col-12 p-md-3">
            priority
          </label>
          <div className="p-col-12 p-md-9">
            <span>{this.priorityTemplate(order.priority)}</span>
          </div>
        </div>

        {order.home_folder ? (
          <div className="p-d-flex">
            <label htmlFor="home_folder" className="p-col-12 p-md-3">
              home folder
            </label>
            <div className="p-col-12 p-md-9 p-d-flex p-flex-wrap">
              <Link target="_blank" className="p-mr-3" to="">
                <FontAwesomeIcon icon={['fal', 'external-link']} className={'p-mr-1'} />
                {order.home_folder}
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/** Size */}
        <div className="p-d-flex p-mb-4">
          <label htmlFor="size" className="p-col-12 p-md-3">
            size
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.size}</span>
          </div>
        </div>

        {/** Summary */}
        <div className="p-d-flex p-mb-4">
          <label htmlFor="summary" className="p-col-12 p-md-3">
            summary
          </label>
          <div className="p-col-12 p-md-9">
            <span>{order.summary ?? ''}</span>
          </div>
        </div>

        {order.owner_company ? (
          <div className="p-d-flex">
            <label htmlFor="owner" className="p-col-12 p-md-3">
              owner
            </label>
            <div className="p-col-12 p-md-9">
              <span>{order.owner_company?.name ?? ''}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.owner_contact_ref ? (
          <div className="p-d-flex">
            <label htmlFor="owner" className="p-col-12 p-md-3">
              contact
            </label>
            <div id="owner" className="p-col-12 p-md-9">
              <span>{order.owner_contact_ref?.label}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {shippingAddress ? (
          <div className="p-d-flex p-mb-4">
            <label htmlFor="shipping_address" className="p-col-12 p-md-3">
              shipping to
            </label>
            <div className="p-col-12 p-md-12 p-lg-9">
              <span>{shippingAddress}</span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/** Users */}
        {order.created_by_id || order.created_at ? (
          <div className="p-d-flex">
            <label htmlFor="submitted" className="p-col-12 p-md-3">
              created
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.created_by_id ?? ''} ${
                  order.created_at ? DateTime.fromISO(order.created_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.submitted_by || order.submitted_at ? (
          <div className="p-d-flex">
            <label htmlFor="submitted" className="p-col-12 p-md-3">
              submitted
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.submitted_by ?? ''} ${
                  order.submitted_at ? DateTime.fromISO(order.submitted_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}

        {order.approved_by || order.approved_at ? (
          <div className="p-d-flex">
            <label htmlFor="approved" className="p-col-12 p-md-3">
              approved
            </label>
            <div className="p-col-12 p-md-9">
              <span>
                {`${order.approved_by ?? ''} ${
                  order.approved_at ? DateTime.fromISO(order.approved_at.toString()).toFormat(formats.date) : ''
                }`}
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}

export default OrderDetail;
