import React from 'react';

import OrderListComponent from '../Orders/OrderListComponent';

class ShippingOrdersComponent extends React.Component<{}> {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  render() {
    return <OrderListComponent mode={'In Shipping'} />;
  }
}

export default ShippingOrdersComponent;
