import {PriceListProductPrice} from 'two-core';
import React from 'react';

interface Props {
  item: PriceListProductPrice;
}

class PriceListProductPriceComponent extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {item} = this.props;
    return (
      <>
        <div key={item.sku}>
          <div className="p-d-flex p-ai-center">
            <label htmlFor="stage" className="p-col-12 p-md-3">
              product price
            </label>
            <div className="p-col-12 p-md-9">
              {item.value_type === 'picklist' ? (
                <table
                  style={{
                    borderSpacing: '0',
                  }}
                >
                  <thead
                    style={{
                      background: '#E9EBED',
                      margin: '0px',
                    }}
                  >
                    <tr
                      style={{
                        background: '#E9EBED',
                        margin: '0',
                      }}
                    >
                      {Array.isArray(item.value) &&
                        item.value.map((valueItem, i) => {
                          return (
                            <th
                              key={i}
                              style={{
                                background: '#E9EBED',
                                border: '1px solid black',
                                margin: '0px',
                                padding: '5px',
                              }}
                            >
                              {valueItem.key}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      margin: '0px',
                    }}
                  >
                    <tr
                      style={{
                        margin: '0',
                      }}
                    >
                      {Array.isArray(item.value) &&
                        item.value.map((valueItem, i) => {
                          return (
                            <td
                              key={i}
                              style={{
                                border: '1px solid black',
                                textAlign: 'center',
                              }}
                            >
                              {valueItem.value}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
              ) : (
                <span>{item.value}</span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PriceListProductPriceComponent;
