import {ApiService, AuthService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter} from 'two-core';

class InvoicesService extends ApiService {
  endpoint = '';

  constructor(aus: AuthService) {
    super(aus);
    //    this.endpoint = config().endpoints.invoices ?? '';
  }

  async getInvoices(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /*  async updateInvoice(id: string, data: InvoicePatch): Promise<Invoice> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Invoice);
      })
      .catch(() => {
        return Promise.reject();
      });
  }*/
}

export default InvoicesService;
