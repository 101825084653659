import React from 'react';
import {Toast} from 'primereact/toast';
import {Dialog} from 'primereact/dialog';
import {AppContext, ToastService} from 'two-app-ui';
import {ProgressSpinner} from 'primereact/progressspinner';
import {MessageService} from 'two-app-ui';
import {Subscription} from 'rxjs';
import {Company, CompanyPatch, QueryParameter} from 'two-core';
import {Button} from 'primereact/button';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';

interface Props {
  company: Company;
  showDialog: boolean;
  onHide: () => void;
}

interface State {
  loading: boolean;
  selectedCompany: Company | undefined;
  companies: Company[];
}

class AssignSubDealerDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectedCompany: undefined,
      companies: [],
    };

    this.toast = React.createRef();

    this.renderEditCompanyFooter = this.renderEditCompanyFooter.bind(this);
    this.saveCompany = this.saveCompany.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.toastService = this.context.toastService;
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  async loadData() {
    const params: QueryParameter = {
      aggregate: true,
    };
    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const companies = data.records as Company[];
        this.setState({
          companies: companies,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, company records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  saveCompany() {
    this.setState({loading: true});

    const company = this.props.company;
    const selectedCompany = this.state.selectedCompany;
    if (selectedCompany) {
      const updatedCompany: CompanyPatch = {
        parent_id: company.id ?? '',
      };
      this.companiesService
        ?.updateCompany(selectedCompany.id!, updatedCompany)
        .then(() => {
          MessageService.sendMessage(messages.subDealerUpdate);
          this.toastService?.showSuccess(
            this.toast,
            `Company ${this.props.company.trading_as ?? this.props.company.name} updated successfully.`
          );
          this.closeDialog();
        })
        .catch(error => {
          this.toastService?.showError(this.toast, `Sorry, ${selectedCompany.name} update failed, please try again.`);
          console.error(error);
          this.setState({loading: false});
        });
    } else {
      this.toastService?.showError(this.toast, 'Sorry, Compoany should be selected.');
      this.setState({loading: false});
    }
  }

  renderEditCompanyFooter() {
    return (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button
          label="cancel"
          className={'p-mr-2 p-button-text'}
          onClick={() => {
            this.closeDialog();
          }}
        />
        <Button label="save" className={'p-mr-2'} onClick={() => this.saveCompany()} autoFocus />
      </div>
    );
  }

  closeDialog() {
    this.props.onHide();
    this.setState({
      loading: false,
      selectedCompany: undefined,
    });
  }

  changeSelectedCompany(e: DropdownChangeParams) {
    const value = e.target.value as Company;
    this.setState({selectedCompany: value});
  }

  render() {
    return (
      <>
        <Dialog
          header={'Assign Sub Dealer Company'}
          visible={this.props.showDialog}
          style={{width: '40%'}}
          modal
          className="p-fluid"
          onHide={this.closeDialog}
          onShow={this.loadData}
          footer={this.renderEditCompanyFooter}
        >
          {!this.state.loading ? (
            <div className="p-grid p-ai-center">
              <label htmlFor="parent_id" className="p-col-12 p-md-3">
                company
              </label>
              <div className="p-col-12 p-md-9">
                <span className="p-fluid">
                  <Dropdown
                    filter
                    name="parent_id"
                    options={this.state.companies}
                    showClear
                    value={this.state.selectedCompany}
                    onChange={e => {
                      this.changeSelectedCompany(e);
                    }}
                    optionLabel="name"
                  />
                </span>
              </div>
            </div>
          ) : (
            <div className="p-d-flex p-flex-row p-ai-center">
              <ProgressSpinner />
            </div>
          )}
        </Dialog>
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default AssignSubDealerDialog;
