import React from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, ToastService} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from 'primereact/progressspinner';
import {messages} from '../../config/messages';
import {DropdownOption, PriceDefinitionRevision, PriceList, PriceListVersion, QueryParameter} from 'two-core';
import PriceListDefinitionRevisionsService from '../../services/PriceListDefinitionRevisionsService';
import {Dropdown} from 'primereact/dropdown';
import {InputSwitch} from 'primereact/inputswitch';
import PriceListsService from '../../services/PriceListsService';
import PriceListVersionService from '../../services/PriceListVersionService';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  onSave: () => void;
  toast: React.RefObject<Toast>;
  priceLists: PriceList[];
}

interface State {
  loading: boolean;
  priceList: PriceList;
  priceLists: PriceList[];
  copyPriceList: PriceList;
  priceDefinitionRevisions: PriceDefinitionRevision[];
  priceDefinitionRevisionId: number;
  copySwitch: boolean;
  version: PriceListVersion;
}

class AddPriceListDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  toastService: ToastService | null = null;
  priceListsService: PriceListsService | null = null;
  priceListDefinitionRevisionsService: PriceListDefinitionRevisionsService | null = null;
  priceListVersionsService: PriceListVersionService | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      priceList: {
        name: '',
        stage: 'Draft',
      },
      priceLists: [],
      copyPriceList: {
        name: '',
        stage: 'Draft',
      },
      priceDefinitionRevisions: [],
      priceDefinitionRevisionId: 0,
      copySwitch: false,
      version: {
        index: 0,
        price_list_id: 0,
        base_definition_revision_id: 0,
        stage: 'Draft',
        created_at: new Date(),
        created_by: '',
      },
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.loadData = this.loadData.bind(this);
    this.loadDefinitions = this.loadDefinitions.bind(this);
    this.loadPriceLists = this.loadPriceLists.bind(this);
    this.loadLastRecord = this.loadLastRecord.bind(this);
    this.createVersion = this.createVersion.bind(this);
    this.getAllActivePriceListsOptions = this.getAllActivePriceListsOptions.bind(this);
    this.getDefinitionsRevisionsOptions = this.getDefinitionsRevisionsOptions.bind(this);
  }

  componentDidMount() {
    this.toastService = this.context.toastService;
    this.priceListsService = this.context.priceListsService;
    this.priceListDefinitionRevisionsService = this.context.priceListDefinitionRevisionsService;
    this.priceListVersionsService = this.context.priceListVersionsService;
  }

  loadData() {
    this.loadDefinitions();
    this.loadPriceLists();
  }

  loadDefinitions() {
    this.setState({loading: true});
    this.priceListDefinitionRevisionsService
      ?.getPriceListDefinitionRevisions({
        orderBys: [
          JSON.stringify({
            field: 'id',
            direction: 'DESC',
          }),
        ],
        aggregate: false,
      })
      .then(data => {
        const definitions = data.records as PriceDefinitionRevision[];
        this.setState({
          priceDefinitionRevisions: definitions,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(
          this.props.toast,
          'Sorry, price definition revisions load failed, please try again.'
        );
        console.error(error);
        this.setState({loading: false});
      });
  }

  loadPriceLists() {
    this.setState({loading: true});
    const filters = [];
    const sortBy: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'deleted',
        value: false,
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };
    this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const lists = data.records as PriceList[];
        this.setState({
          priceLists: lists,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, price lists load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  setValue(title: string, value: string | boolean) {
    const priceList = this.state.priceList;
    const updatedPriceList = {
      ...priceList,
      [title]: value,
    };
    this.setState({priceList: updatedPriceList});
  }

  hideDialog() {
    this.setState({
      priceList: {
        name: '',
        stage: 'Draft',
      },
      loading: false,
    });
    this.props.onHide();
    MessageService.sendMessage(messages.priceListUpdated);
  }

  loadLastRecord() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    sortBy.push(
      JSON.stringify({
        field: 'id',
        direction: 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
    };

    return this.priceListsService
      ?.getPriceLists(params)
      .then(data => {
        const priceList = (data.records as PriceList[])[0];

        this.setState({
          priceList: priceList,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, price list load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  onSave() {
    this.setState({
      priceList: {
        name: '',
        stage: 'Draft',
      },
      loading: false,
    });
    this.props.onSave();
    MessageService.sendMessage(messages.priceListCreated);
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';

    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  async createVersion() {
    await this.loadLastRecord();
    const {priceList, priceDefinitionRevisionId, copySwitch, version} = this.state;
    const newVersion: PriceListVersion = {
      price_list_id: priceList.id ?? 0,
      index: 1,
      created_by: this.getCurrentUserId(),
      created_at: new Date(),
      stage: 'Draft',
      base_definition_revision_id: priceDefinitionRevisionId,
    };

    if (copySwitch) {
      return this.priceListVersionsService?.createPriceListVersion(newVersion, version.id);
    } else {
      return this.priceListVersionsService?.createPriceListVersion(newVersion);
    }
  }

  getDefinitionsRevisionsOptions() {
    const options: DropdownOption[] = [];
    this.state.priceDefinitionRevisions.forEach(definition => {
      options.push({
        label: definition.id + ' (' + definition.stage + ')',
        value: definition.id as number,
      });
    });

    return options;
  }

  getAllActivePriceListsOptions() {
    const options: DropdownOption[] = [];
    this.state.priceLists.forEach(priceList => {
      if (priceList.deleted === false) {
        options.push({
          label: priceList.name,
          value: priceList.id as number,
        });
      }
    });

    return options;
  }

  getVersionsOptions(priceList: PriceList) {
    const options: DropdownOption[] = [];
    if (priceList.price_list_versions) {
      priceList.price_list_versions.forEach(version => {
        if (version !== null) {
          options.push({
            label: version.index + ' (' + version.stage + ')',
            value: version.id as number,
          });
        }
      });
    }

    return options;
  }

  async save() {
    this.setState({
      loading: true,
    });
    const promises = [];
    const priceList = this.state.priceList;
    promises.push(this.priceListsService?.createPriceList(priceList), this.createVersion());
    Promise.all(promises)
      .then(() => {
        this.toastService?.showSuccess(this.props.toast, 'Price list created successfully.');
        this.onSave();
      })
      .catch(error => {
        this.toastService?.showError(this.props.toast, 'Sorry, Price list create failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  renderFooter() {
    return !this.state.loading ? (
      <div className={'p-d-flex p-my-4 p-justify-end'}>
        <Button label="cancel" className={'p-mr-2 p-button-text'} onClick={() => this.props.onHide()} />
        <Button
          label="save"
          className={'p-mr-2'}
          onClick={() => {
            this.save();
          }}
          autoFocus
        />
      </div>
    ) : (
      <></>
    );
  }

  render() {
    const {loading, priceList, copySwitch, priceDefinitionRevisionId, copyPriceList, version} = this.state;
    return (
      <Dialog
        header={'Creat New Price List'}
        footer={this.renderFooter}
        visible={this.props.showDialog}
        style={{width: '80%'}}
        modal
        onHide={this.hideDialog}
        onShow={this.loadData}
      >
        {!loading ? (
          <>
            <div className="p-grid">
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="name" className="p-col-2">
                  Create a PL with the name
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <InputText
                      value={priceList.name ?? ''}
                      onChange={e => {
                        const name = e.target.value;
                        this.setValue('name', name);
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="definition" className="p-col-3">
                  and base its draft version on price definition revision
                </label>
                <div className="p-col-4">
                  <span className="p-fluid">
                    <Dropdown
                      options={this.getDefinitionsRevisionsOptions()}
                      value={priceDefinitionRevisionId}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState({
                          priceDefinitionRevisionId: value,
                        });
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className="p-d-flex p-ai-center p-col-12">
                <label htmlFor="copy" className="p-col-2">
                  And copy prices
                </label>
                <div className="p-col-1">
                  <span className="p-fluid">
                    <InputSwitch
                      name="showItemsSwitch"
                      className={'p-as-center'}
                      checked={copySwitch}
                      onChange={() => {
                        this.setState({copySwitch: !copySwitch});
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="price-list" className="p-col-1">
                  from
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      options={copySwitch ? this.getAllActivePriceListsOptions() : []}
                      disabled={!copySwitch}
                      value={copyPriceList.id ?? ''}
                      onChange={e => {
                        const pL = this.state.priceLists.find(list => list.id === e.target.value);
                        this.setState({
                          copyPriceList: pL ? pL : this.state.copyPriceList,
                        });
                      }}
                    />
                  </span>
                </div>
                <label htmlFor="version" className="p-col-1">
                  and version
                </label>
                <div className="p-col-3">
                  <span className="p-fluid">
                    <Dropdown
                      options={copyPriceList.id && copyPriceList.id > 0 ? this.getVersionsOptions(copyPriceList) : []}
                      value={version.id ?? ''}
                      disabled={!copySwitch}
                      onChange={e => {
                        const newVersion = copyPriceList.price_list_versions
                          ? copyPriceList.price_list_versions.find(list => list.id === e.target.value)
                          : version;
                        this.setState({
                          version: newVersion ? newVersion : version,
                        });
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-d-flex p-flex-row p-ai-center">
            <ProgressSpinner />
          </div>
        )}
      </Dialog>
    );
  }
}
export default AddPriceListDialog;
