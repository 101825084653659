import {Column} from 'primereact/column';
import {DataTablePageParams, DataTableSortOrderType, DataTableSortParams} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import {MenuItemOptions} from 'primereact/menuitem';
import {MultiSelect, MultiSelectChangeParams} from 'primereact/multiselect';
import {Toast} from 'primereact/toast';
import React from 'react';
import {Subscription} from 'rxjs';
import {
  AppColumnMenuBodyTemplate,
  AppContext,
  AppMenuItem,
  AppMenuItemTemplate,
  MessageService,
  ToastService,
  TwoDataTable,
} from 'two-app-ui';
import {Company, CompanyPatch, QueryParameter} from 'two-core';
import {messages} from '../../config/messages';
import {companyStages, companyStates, values} from '../../config/values';
import CompaniesService from '../../services/CompaniesService';
import AssignSubDealerDialog from './AssignSubDealerDialog';

interface Props {
  company: Company;
}

interface State {
  loading: boolean;
  items: Company[];
  selectedItems: Company[];
  total_items: number;
  activeFilters: {};
  filters: {
    name: string;
    account_number: string;
    state: string;
    stage: string;
    sales_rep: string;
  };
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  showAssignSubDealerDialog: boolean;
}

class CompanySubDealers extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  toastService: ToastService | null = null;

  messageSendSubscription: Subscription = new Subscription();
  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      selectedItems: [],
      total_items: 0,
      activeFilters: {},
      filters: {
        name: '',
        account_number: '',
        state: '',
        stage: '',
        sales_rep: '',
      },
      pagination: {
        pageSize: 10,
        offset: 0,
      },
      sortBy: null,
      showAssignSubDealerDialog: false,
    };

    this.toast = React.createRef();

    this.onPageChange = this.onPageChange.bind(this);
    this.onSort = this.onSort.bind(this);
    this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
    this.hideAssignSubDealerDialog = this.hideAssignSubDealerDialog.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.toastService = this.context.toastService;

    this.messageSendSubscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.subDealerUpdate) {
        this.loadData();
      }
    });

    this.loadData();
  }

  componentWillUnmount() {
    this.messageSendSubscription.unsubscribe();

    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  loadData() {
    this.setState({loading: true});
    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'parent_id',
        value: this.props.company.id,
      })
    );

    if (this.state.filters.name) {
      filters.push(
        JSON.stringify({
          field: 'name',
          value: this.state.filters.name,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.account_number) {
      filters.push(
        JSON.stringify({
          field: 'account_number',
          value: this.state.filters.account_number,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.sales_rep) {
      filters.push(
        JSON.stringify({
          field: 'user.full_name',
          value: this.state.filters.sales_rep,
          condition: 'iLike',
        })
      );
    }

    if (this.state.filters.state) {
      filters.push(
        JSON.stringify({
          field: 'state',
          value: this.state.filters.state,
          condition: 'in',
        })
      );
    }

    if (this.state.filters.stage && this.state.filters.stage.length) {
      filters.push(
        JSON.stringify({
          field: 'stage',
          value: this.state.filters.stage,
          condition: 'in',
        })
      );
    }

    this.setState({activeFilters: {...filters}});

    const sortBy = JSON.stringify({
      field: this.state.sortBy?.field ?? 'name',
      direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
    });

    const params: QueryParameter = {
      offset: this.state.pagination.offset,
      page_size: this.state.pagination.pageSize,
      orderBys: [sortBy],
      aggregate: true,
      filters: filters,
    };

    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const companies = (data.records as Company[]) ?? [];
        this.handleSelectedItems(companies);
        this.setState({
          items: companies,
          total_items: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  initMenuItems(): AppMenuItem[] {
    const menuItems: AppMenuItem[] = [];

    const selectedItems = this.state.selectedItems;
    const selectedItemsLenght = selectedItems.length;

    const addNewMenuItem: AppMenuItem = {
      label: 'Assign',
      faIcon: ['far', 'plus'],
      template: (item: AppMenuItem, options: MenuItemOptions) => {
        return <AppMenuItemTemplate item={item} options={options} />;
      },
      command: () => {
        this.showAssignSubDealerDialog();
      },
    };
    menuItems.push(addNewMenuItem);

    if (selectedItemsLenght === 1) {
      const editMenuItem: AppMenuItem = {
        label: 'Remove',
        faIcon: ['far', 'times'],
        template: (item: AppMenuItem, options: MenuItemOptions) => {
          return <AppMenuItemTemplate item={item} options={options} />;
        },
        command: () => {
          this.removeSubDealer();
        },
      };
      menuItems.push(editMenuItem);
    }

    return menuItems;
  }

  showAssignSubDealerDialog() {
    this.setState({showAssignSubDealerDialog: true});
  }

  hideAssignSubDealerDialog() {
    this.setState({showAssignSubDealerDialog: false});
  }

  removeSubDealer() {
    this.setState({loading: true});

    const selectedCompany = this.state.selectedItems[0];
    if (selectedCompany) {
      const updatedCompany: CompanyPatch = {
        parent_id: undefined,
      };
      this.companiesService
        ?.updateCompany(selectedCompany.id!, updatedCompany)
        .then(() => {
          MessageService.sendMessage(messages.subDealerUpdate);
          this.toastService?.showSuccess(this.toast, `Sub Dealer ${selectedCompany.name} removed successfully.`);
        })
        .catch(error => {
          this.toastService?.showError(
            this.toast,
            `Sorry, sub dealer ${selectedCompany.name} remove failed, please try again.`
          );
          console.error(error);
          this.setState({loading: false});
        });
    } else {
      this.toastService?.showError(
        this.toast,
        `Sorry, ${this.props.company.trading_as ?? this.props.company.name} update failed, please try again.`
      );
      this.setState({loading: false});
    }
  }

  async onPageChange(e: DataTablePageParams) {
    await this.setState({pagination: {offset: e.first, pageSize: e.rows}});
    this.loadData();
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  async onFilterChange(e: React.ChangeEvent<HTMLInputElement> | MultiSelectChangeParams) {
    const value = e.target.value;
    const name = e.target.name;

    await this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      },
    });
    this.loadData();
  }

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      this.onFilterChange(event);
    }, values.stopTypingDetection);
  };

  handleSelectedItems(allItems: Company[]) {
    const selectedItems = [...this.state.selectedItems];
    const items: Company[] = allItems.filter(item => {
      return selectedItems.find(selectedItem => {
        return selectedItem.id === item.id;
      });
    });

    this.setChangeSelectedItems(items);
  }

  setChangeSelectedItems(items: Company[]) {
    this.setState({selectedItems: items});
  }

  async setChangeSelectedItem(item: Company) {
    const items = [...this.state.selectedItems];
    const existingItem = items.find(i => i.id === item.id);
    if (!existingItem) {
      items.push(item);
      await this.setState({selectedItems: items});
    }
  }

  nameBodyTemplate(rowData: Company) {
    return (
      <AppColumnMenuBodyTemplate
        key={rowData.id}
        rowItemIdentifier={rowData?.id?.toString() ?? ''}
        isDynamicMenuItems={true}
        initMenuItems={() => this.initMenuItems()}
        selectedItems={this.state.selectedItems}
        handleChangeSelectedItems={() => this.setChangeSelectedItem(rowData)}
      >
        {`${rowData.name} ${rowData.trading_as ?? ''}`}
      </AppColumnMenuBodyTemplate>
    );
  }

  render() {
    const nameFilter = (
      <InputText
        name="name"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const acountNumberFilter = (
      <InputText
        name="account_number"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const salesRepFilter = (
      <InputText
        name="sales_rep"
        className="form-filter"
        onChange={e => {
          this.handleFilterChange(e);
        }}
      />
    );

    const stageFilter = (
      <MultiSelect
        value={this.state.filters.stage}
        options={companyStages}
        name="stage"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    const stateFilter = (
      <MultiSelect
        value={this.state.filters.state}
        options={companyStates}
        name="state"
        className="form-filter"
        onChange={e => {
          this.onFilterChange(e);
        }}
        showClear
      />
    );

    return (
      <div className="p-d-flex p-p-2" style={{height: '100%'}}>
        <div id="subdealers_list_page" className="page-container">
          <TwoDataTable
            style={{height: '100%'}}
            pageSizeIdentifier={'subdealers_list_page'}
            heightToScroll={undefined}
            selectedItems={this.state.selectedItems}
            loading={this.state.loading}
            rows={this.state.pagination.pageSize}
            first={this.state.pagination.offset}
            activeFilters={this.state.activeFilters}
            value={this.state.items}
            totalRecords={this.state.total_items}
            onPage={e => this.onPageChange(e as DataTablePageParams)}
            onSort={e => this.onSort(e)}
            sortField={this.state.sortBy?.field}
            sortOrder={this.state.sortBy?.order}
            initMenuItems={() => this.initMenuItems()}
            selectionMode="multiple"
            handleChangeSelectedItems={items => this.setChangeSelectedItems(items as unknown as Company[])}
          >
            <Column
              header="Company Name"
              field="name"
              body={item => this.nameBodyTemplate(item)}
              filter
              filterElement={nameFilter}
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
            />
            <Column
              header="Account Name"
              field="account_number"
              filter
              filterElement={acountNumberFilter}
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
            />
            <Column
              header="State"
              field="state"
              filter
              filterElement={stateFilter}
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
            />
            <Column
              header="Stage"
              field="stage"
              filter
              filterElement={stageFilter}
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
            />

            <Column
              header="Sales Rep"
              field="sales_rep_user.full_name"
              filter
              filterElement={salesRepFilter}
              style={{width: '180px'}}
              sortable
              showFilterMenu={false}
            />
          </TwoDataTable>
          <Toast ref={this.toast} />
          <AssignSubDealerDialog
            showDialog={this.state.showAssignSubDealerDialog}
            onHide={() => this.hideAssignSubDealerDialog()}
            company={this.props.company}
          />
        </div>
      </div>
    );
  }
}

export default CompanySubDealers;
