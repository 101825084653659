import React from 'react';
import {DataTableSortParams, DataTableSortOrderType, DataTableExpandedRows} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {AppContext, ToastService, TwoDataTable} from 'two-app-ui';
import {Toast} from 'primereact/toast';
import {QueryParameter, Contact, Company, CompanyContact} from 'two-core';
import CompaniesService from '../../services/CompaniesService';
import {NavLink} from 'react-router-dom';

interface Props {
  contact: Contact;
}

interface State {
  loading: boolean;
  items: Company[];
  totalItems: number;
  activeFilters: {};
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  expandedRows: DataTableExpandedRows[];
}

class ContactCompanyListComponent extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  toastService: ToastService | null = null;

  toast: React.RefObject<Toast>;
  typingTimer: NodeJS.Timeout | undefined = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      items: [],
      totalItems: 0,
      loading: false,
      activeFilters: {},
      sortBy: null,
      expandedRows: [],
    };

    this.toast = React.createRef();
    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.toastService = this.context.toastService;

    this.loadData();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }

  async loadData() {
    this.setState({loading: true});
    const filters: string[] = [];
    const sortBy: string[] | undefined = [];

    filters.push(
      JSON.stringify({
        field: 'contact.id',
        value: this.props.contact.id,
      })
    );

    const sortByField = this.state.sortBy?.field ?? 'name';

    sortBy.push(
      JSON.stringify({
        field: sortByField,
        direction: this.state.sortBy?.order === 1 ? 'ASC' : 'DESC',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      orderBys: sortBy,
      aggregate: true,
    };

    this.setState({activeFilters: {...filters}});

    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const dataRecords = (data.records as Company[]) ?? [];

        this.setState({
          items: dataRecords,
          totalItems: data.total_records ?? 0,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
        this.setState({loading: false});
      });
  }

  async onSort(e: DataTableSortParams) {
    await this.setState({sortBy: {field: e.sortField, order: e.sortOrder}});
    this.loadData();
  }

  render() {
    const nameBodyTemplate = (company: Company) => {
      return <NavLink to={`/company/${company.id}`}>{`${company.trading_as ?? ''} (${company.name})`}</NavLink>;
    };

    const inRoleBodyTemplate = (company: Company) => {
      let compContact: CompanyContact | undefined = undefined;
      if (company.id && this.props.contact.company_contacts) {
        compContact = this.props.contact.company_contacts?.find(
          compContact => compContact.contact_id! === this.props.contact.id && compContact.company_id! === company.id
        );
      }
      return <span>{compContact?.in_role ?? ''}</span>;
    };

    return (
      <div id="contact_company_page_container" className="page-container">
        <TwoDataTable
          id={'contact-companies-table'}
          sizeIdentifiers={[]}
          selectedItems={[]}
          rows={this.state.items.length}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          onSort={e => this.onSort(e)}
          loading={this.state.loading}
          activeFilters={{}}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          showPaging={false}
          hideFilter={true}
        >
          <Column header="Company" field="trading_as" sortable body={nameBodyTemplate} />
          <Column header="Role" field="role" body={inRoleBodyTemplate} />
        </TwoDataTable>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default ContactCompanyListComponent;
