import React from 'react';
import {Company} from 'two-core';
import {TwoDataTable} from 'two-app-ui';
import {Column} from 'primereact/column';
import {DataTableSortOrderType} from 'primereact/datatable';

interface Props {
  customers: Company[];
}

interface State {
  loading: boolean;
  items: Company[];
  totalItems: number;
  pagination: {
    pageSize: number;
    offset: number;
  };
  sortBy: {
    field: string;
    order: DataTableSortOrderType;
  } | null;
  filters: {};
}

class PriceListCustomers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      totalItems: 0,
      pagination: {
        pageSize: 25,
        offset: 0,
      },
      sortBy: null,
      filters: {},
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      items: this.props.customers,
      totalItems: this.props.customers.length,
    });
  }

  render() {
    return (
      <div id="price-list-customers-page" className="page-container">
        <TwoDataTable
          id={'price-list-customers-table'}
          rows={this.state.pagination.pageSize}
          first={this.state.pagination.offset}
          sortField={this.state.sortBy?.field}
          sortOrder={this.state.sortBy?.order}
          loading={this.state.loading}
          value={this.state.items}
          totalRecords={this.state.totalItems}
          activeFilters={{}}
          selectedItems={[]}
        >
          <Column header="Name" field="name" style={{width: '400px'}} />
          <Column header="State" field="state" style={{width: '180px'}} />
          <Column header="Stage" field="stage" style={{width: '180px'}} />
        </TwoDataTable>
      </div>
    );
  }
}

export default PriceListCustomers;
